// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_ellipsis__D6aY7 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.styles_formControlWide__StcWa {
  width: 250px;
}

.styles_formControlNumber__yEA2s {
  width: 100px;
}

.styles_formControlUnit__bVTu3 {
  min-width: 200px;
}

.styles_formRow__vZ6Sf {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 16px;
}

.styles_formColumn__ZQYCT {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/VehiclesPage/MultiStepForm/FormStep2/styles.module.scss"],"names":[],"mappings":"AACA;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,WAAA;EACA,mBAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,aAAA;AAAF","sourcesContent":["\n.ellipsis {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.formControlWide {\n  width: 250px;\n}\n\n.formControlNumber {\n  width: 100px;\n}\n\n.formControlUnit {\n  min-width: 200px;\n}\n\n.formRow {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  width: 100%;\n  margin-bottom: 16px;\n}\n\n.formColumn {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  margin: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ellipsis": `styles_ellipsis__D6aY7`,
	"formControlWide": `styles_formControlWide__StcWa`,
	"formControlNumber": `styles_formControlNumber__yEA2s`,
	"formControlUnit": `styles_formControlUnit__bVTu3`,
	"formRow": `styles_formRow__vZ6Sf`,
	"formColumn": `styles_formColumn__ZQYCT`
};
export default ___CSS_LOADER_EXPORT___;
