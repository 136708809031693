import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal, Box, TextField } from '@mui/material';
import { IVehicle } from "../../api/authApi";
import { VehiclesApi } from "../../api/vehiclesApi";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useFileDownload} from "../../hooks/useFileDownload";

const extractDate = (fileName: string | null): string | null => {
    if (!fileName) return null;
    const regex = /(\d{4}-\d{2}-\d{2})(?!.*\d{4}-\d{2}-\d{2})/;
    const match = fileName.match(regex);
    return match ? match[0] : null;
};

const isDateClose = (dateStr: string | null): boolean => {
    if (!dateStr) return false;
    const date = dayjs(dateStr, 'YYYY-MM-DD');
    const today = dayjs();
    return date.isBefore(today) || date.isSame(today, 'day');
};


const DocumentsPage = () => {
    const [vehicles, setVehicles] = useState<IVehicle[]>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
    const [selectedVehicle, setSelectedVehicle] = useState<IVehicle | null>(null);
    const [selectedField, setSelectedField] = useState<keyof IVehicle | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const vehiclesApi = new VehiclesApi();
    const { downloadFile, loading, error } = useFileDownload();

    const handleVehicles = async () => {
        const data = await vehiclesApi.getVehicles();
        setVehicles(data);
    }

    const handleDateCellClick = (vehicle: IVehicle, field: keyof IVehicle) => {
        setSelectedVehicle(vehicle);
        setSelectedField(field);
        const dateStr = extractDate(vehicle[field] as string);
        setSelectedDate(dateStr ? dayjs(dateStr, 'YYYY-MM-DD') : null); // Directly use 'YYYY-MM-DD'
        setOpenModal(true);
    };

    const handleUpload = async (file: File | null) => {
        if (!file || !selectedVehicle || !selectedField) {
            console.error("No file, vehicle, or field selected.");
            return;
        }
        try {
            // Upload the file
            const formData = new FormData();
            formData.append('file', file);
            const formattedDate = selectedDate ? selectedDate.format('YYYY-MM-DD') : '';
            const response = await vehiclesApi.postVehicleDocs(formData, formattedDate);

            const { id, ...vehicleWithoutId } = selectedVehicle;
            const updatedVehicle = {
                ...vehicleWithoutId,
                [selectedField]: response,
            };
            // Send the PUT request to update the vehicle
            await vehiclesApi.putVehicle(updatedVehicle, id as number);
            console.log("File uploaded and vehicle updated:", response);
            // Close the modal after uploading
            setOpenModal(false);
        } catch (error) {
            console.error("Upload or update error:", error);
        } finally {
            await handleVehicles();
        }
    };


    const handleDownload = async (vehicleId: number, docType: string) => {
        try {
            let apiDocType:string;
            switch (docType) {
                case 'doc_insurance':
                    apiDocType = 'insurance';
                    break;
                case 'doc_maintenance':
                    apiDocType = 'maintenance';
                    break;
                case 'doc_maintenance_partly':
                    apiDocType = 'maintenance_partly';
                    break;
                case 'doc_maintenance_full':
                    apiDocType = 'maintenance_full';
                    break;
                default:
                    throw new Error('Invalid document type');
            }
            await downloadFile({
                apiCall: () => vehiclesApi.downloadVehicleDoc(vehicleId, apiDocType)
            });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        handleVehicles();
    }, []);

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow sx={{backgroundColor: '#C3B1E1'}}>
                            <TableCell sx={{fontWeight: 'bold'}}>Марка + Модель</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>Тип техники</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>Гаражный №</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>ГРНЗ</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>Срок действия страховки</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>Срок действия тех.осмотра</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>ЧТО</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>ПТО</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {vehicles.map((vehicle, index) => (
                            <TableRow key={index}>
                                <TableCell>{`${vehicle.marka} ${vehicle.model}`}</TableCell>
                                <TableCell>{vehicle.vehicle_type}</TableCell>
                                <TableCell>{vehicle.garage_number}</TableCell>
                                <TableCell>{vehicle.gos_number}</TableCell>
                                <TableCell
                                    onClick={() => handleDateCellClick(vehicle, 'doc_insurance')}
                                    sx={{
                                        backgroundColor: isDateClose(extractDate(vehicle.doc_insurance)) ? 'lightcoral' : 'inherit',
                                        cursor: 'pointer',
                                        transition: 'transform 0.2s, box-shadow 0.2s',
                                        '&:hover': {
                                            transform: 'scale(1.02)',
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                        },
                                    }}
                                >
                                    {extractDate(vehicle.doc_insurance) ? dayjs(extractDate(vehicle.doc_insurance), 'YYYY-MM-DD').format('DD.MM.YYYY') : '-'}
                                </TableCell>

                                <TableCell
                                    onClick={() => handleDateCellClick(vehicle, 'doc_maintenance')}
                                    sx={{
                                        backgroundColor: isDateClose(extractDate(vehicle.doc_maintenance)) ? 'lightcoral' : 'inherit',
                                        cursor: 'pointer',
                                        transition: 'transform 0.2s, box-shadow 0.2s',
                                        '&:hover': {
                                            transform: 'scale(1.02)',
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                        },
                                    }}
                                >
                                    {extractDate(vehicle.doc_maintenance) ? dayjs(extractDate(vehicle.doc_maintenance), 'YYYY-MM-DD').format('DD.MM.YYYY') : '-'}
                                </TableCell>

                                <TableCell
                                    onClick={() => handleDateCellClick(vehicle, 'doc_maintenance_partly')}
                                    sx={{
                                        backgroundColor: isDateClose(extractDate(vehicle.doc_maintenance_partly)) ? 'lightcoral' : 'inherit',
                                        cursor: 'pointer',
                                        transition: 'transform 0.2s, box-shadow 0.2s',
                                        '&:hover': {
                                            transform: 'scale(1.02)',
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                        },
                                    }}
                                >
                                    {extractDate(vehicle.doc_maintenance_partly) ? dayjs(extractDate(vehicle.doc_maintenance_partly), 'YYYY-MM-DD').format('DD.MM.YYYY') : '-'}
                                </TableCell>

                                <TableCell
                                    onClick={() => handleDateCellClick(vehicle, 'doc_maintenance_full')}
                                    sx={{
                                        backgroundColor: isDateClose(extractDate(vehicle.doc_maintenance_full)) ? 'lightcoral' : 'inherit',
                                        cursor: 'pointer',
                                        transition: 'transform 0.2s, box-shadow 0.2s',
                                        '&:hover': {
                                            transform: 'scale(1.02)',
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                        },
                                    }}
                                >
                                    {extractDate(vehicle.doc_maintenance_full) ? dayjs(extractDate(vehicle.doc_maintenance_full), 'YYYY-MM-DD').format('DD.MM.YYYY') : '-'}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Modal open={openModal} onClose={() => setOpenModal(false)}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            p: 4,
                            borderRadius: 2,
                        }}
                    >
                        <h3>Загрузить файл</h3>
                        <DatePicker
                            label="Срок действия до"
                            value={selectedDate}
                            onChange={(newValue) => setSelectedDate(newValue)}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                            <Button variant="contained" component="label"   disabled={!selectedDate}>
                                Загрузить файл
                                <input
                                    type="file"
                                    hidden
                                    onChange={(e) => {
                                        if (e.target.files) {
                                            setSelectedFile(e.target.files[0]);
                                            handleUpload(e.target.files[0]);
                                        }
                                    }}
                                />
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => handleDownload(selectedVehicle?.id as number, selectedField as string)}
                                disabled={!selectedDate}
                            >
                                Скачать
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </LocalizationProvider>
        </>
    );
};

export default DocumentsPage;
