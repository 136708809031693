import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, SxProps, Theme } from '@mui/material';

interface DeleteConfirmationModalProps {
    open: boolean;
    vehicleMarka: string;
    onClose: () => void;
    onConfirm: () => void;
    buttonStyle: SxProps<Theme>;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({ open, vehicleMarka, onClose, onConfirm, buttonStyle }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Подтверждение удаления</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Вы действительно хотите удалить транспортное средство ({vehicleMarka})? Продолжить?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Отмена
                </Button>
                <Button onClick={onConfirm} sx={buttonStyle} color="secondary">
                    Удалить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmationModal;
