import API from "./api";

export interface IWarehouses{
    name: string;
    city: string;
    address: string;
    id: number;
}
export interface ICategory {
    id: number;
    name: string;
}
export interface IInventory{
    id: number;
    name: string;
    description: string;
    category_id: number;
    warehouse_id: number;
    quantity: number;
    volume: number;
    condition: string;
    comments: string;
    unit: string;
}
export class InventoryApi extends API {
  getWarehouses(): Promise<IWarehouses[]> {
      return this.get(`api/inventory/warehouses`)
  }
  postWarehouses(data: Omit<IWarehouses, 'id'>): Promise<IWarehouses> {
      return this.post(`api/inventory/warehouses`, data)
  }
  getWarehouseById(id: number): Promise<IWarehouses> {
      return this.get(`api/inventory/warehouses/${id}`)
  }
  putWarehouse(id: number, data: Omit<IWarehouses, 'id'> ): Promise<IWarehouses> {
        return this.put(`api/inventory/warehouses/${id}`, data)
  }
  deleteWarehouse(id: number ): Promise<IWarehouses> {
        return this.delete(`api/inventory/warehouses/${id}`)
  }
  getCategories(): Promise<ICategory[]> {
        return this.get(`api/inventory/categories`)
  }
  postCategories(data: string): Promise<ICategory> {
        return this.post(`api/inventory/categories`, {name: data})
  }
  getCategoryById(id: number): Promise<ICategory> {
        return this.get(`api/inventory/categories/${id}`)
  }
  putCategory(id: number, data: Omit<IWarehouses, 'id'> ): Promise<IWarehouses> {
        return this.put(`api/inventory/categories/${id}`, data)
  }
  deleteCategory(id: number ): Promise<IWarehouses> {
        return this.delete(`api/inventory/categories/${id}`)
  }
  getInventory():Promise<IInventory[]> {
        return this.get(`api/inventory`)
  }
  postInventory(data: IInventory): Promise<IInventory> {
        return this.post(`api/inventory`, data)
  }
  getInventoryByItemId(itemId: number): Promise<IInventory> {
        return this.get(`api/inventory/${itemId}`)
  }
  editInventory(itemId: number, data: IInventory): Promise<IInventory> {
        return this.put(`api/inventory/${itemId}`, data)
  }
  deleteInventory(itemId: number) {
        return this.delete(`api/inventory/${itemId}`)
  }
}
