import API from "./api";
import {IFormData} from "../pages/VehiclesPage/VehicleRepair/VehicleRepairForm";
import {IDefectReportForm} from "../pages/VehiclesPage/VehicleRepair/modals/DefectActModal";
import {IInstallForm, InstallationItem} from "../pages/VehiclesPage/VehicleRepair/modals/InstallationActModal";

export class RepairApi extends API {
    getRepair(repairId: string):Promise<IFormData> {
        return this.get(`api/repair_cards/${repairId}`);
    }
    saveRepair(repairId: string, data: IFormData):Promise<IFormData> {
        return this.put(`api/repair_cards/${repairId}`, data);
    }
    finishRepair(repairId: string, data:IFormData):Promise<IFormData> {
        return this.post(`api/repair_cards/${repairId}`, data);
    }
    getInstallationReport(workItemId: string | number):Promise<IInstallForm> {
        return this.get(`api/installation_reports/${workItemId}`)
    }
    putInstallationReport(workItemId: string | number, data: IInstallForm) :Promise<IInstallForm>{
        return this.put(`api/installation_reports/${workItemId}`, data)
    }
    getDefectReport(workItemId: string | number):Promise<IDefectReportForm> {
        return this.get(`api/defect_reports/${workItemId}`)
    }
    putDefectReport(workItemId: string | number, data: IDefectReportForm):Promise<IDefectReportForm> {
        return this.put(`api/defect_reports/${workItemId}`, data)
    }
    downloadRepairDoc(repairId: string){
        return this.getBlob(`api/repair_cards/${repairId}/document`)
    }
    downloadInstallationRepairDoc(repairId: string){
        return this.getBlob(`api/installation_reports/${repairId}/document`)
    }
    downloadDefectRepairDoc(repairId: string){
        return this.getBlob(`api/defect_reports/${repairId}/document`)
    }
}
