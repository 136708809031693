import React, {useCallback, useEffect, useState} from 'react';
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    Autocomplete, TableCell, TableRow, TableContainer, TableBody, Table, TableHead, Paper,
} from '@mui/material';
import { Formik, Field, FieldArray, Form } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import {IWorker} from "../VehicleRepairForm";
import {IInventory, InventoryApi} from "../../../../api/inventoryApi";
import {RepairApi} from "../../../../api/repairApi";
import {useFileDownload} from "../../../../hooks/useFileDownload";

export interface InstallationItem {
    inventory_item_id: string;
    unit: string;
    quantity: string;
    comments: string;
}

interface InstallationActModalProps {
    open: boolean;
    onClose: () => void;
    commission: IWorker[];
    marka: string;
    gos_number: string;
    mileage: string;
    installForm: IInstallForm;
}
export interface IInstallForm {
    application_date: string;
    city: string;
    installation_items: InstallationItem[];
    repair_work_item_id?: string;
}

const InstallationActModal: React.FC<InstallationActModalProps> = ({ open,
                                                                       onClose,
                                                                       commission,
                                                                       mileage,
                                                                       marka,
                                                                       gos_number,
                                                                       installForm}) => {
    const inventoryApi = new InventoryApi();
    const repairApi = new RepairApi();
    const [inventory ,setInventory] = useState<IInventory[]>([]);
    const { downloadFile, loading, error } = useFileDownload();
    const handleGetInventory =  async () => {
        const data =  await inventoryApi.getInventory();
        setInventory(data);
    }
    const handleSubmitValues = async (values: IInstallForm) => {
        await repairApi.putInstallationReport(installForm?.repair_work_item_id as string, values);
        onClose();
    }

    useEffect(() => {
        void handleGetInventory();
    }, []);

    const handleDownload = () => {
        downloadFile({
            apiCall: () => repairApi.downloadInstallationRepairDoc(installForm?.repair_work_item_id as string),
        });
    };


    return (
        <Modal open={open} onClose={onClose} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Box
                sx={{
                    overflow: 'auto',
                    m: 4,
                    backgroundColor: 'background.paper',
                    border: '1px solid #000',
                    borderRadius: '4px',
                    boxShadow: 24,
                    p: 4,
                    width: '75%',
                    maxWidth: '1080px',
                    maxHeight: '90vh',
                }}
            >
                <Formik <IInstallForm>
                    initialValues={installForm}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        handleSubmitValues(values)
                    }}
                >
                    {({ values, handleChange }) => (
                        <Form>
                            <Typography variant="h6" gutterBottom display={'flex'} justifyContent={'flex-start'} paddingBottom={2}>
                                Установочный акт № {installForm.repair_work_item_id}
                            </Typography>

                            {/* City and Date Fields */}
                            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                                <Field
                                    name="application_date"
                                    as={TextField}
                                    label="Дата заявления"
                                    type="date"
                                    size={'small'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <Field
                                    name="city"
                                    as={TextField}
                                    label="Город"
                                    size={'small'}
                                />
                            </Box>

                            {/* Committee Info */}
                            <Typography variant="body1" gutterBottom paddingBottom={2}>
                                Комиссия в составе:
                            </Typography>
                            {commission?.map((member, index) => (
                                <React.Fragment key={index}>
                                    <Typography variant="h6" paddingBottom={2}> {`${member.position} - ${member.full_name}`}</Typography>
                                </React.Fragment>
                            ))}
                            {/* Car Info */}
                            <Typography variant="body1" gutterBottom paddingBottom={2}>
                                Марка автомобиля{' '}
                                <Typography component="span" variant="body1" fontWeight="bold">
                                    {marka}
                                </Typography>
                                , гос. номер{' '}
                                <Typography component="span" variant="body1" fontWeight="bold">
                                    {gos_number}
                                </Typography>
                                , показания спидометра/моточасы -{' '}
                                <Typography component="span" variant="body1" fontWeight="bold">
                                    {mileage}
                                </Typography>
                            </Typography>
                            <Typography paddingBottom={2}>
                                составила настоящий акт о том, что были использованы/установлены следующие ТМЦ:
                            </Typography>
                            {/* Installation Items */}
                            <FieldArray name="installation_items">
                                {({ remove, push }) => (
                                    <>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="installation items table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>№</TableCell>
                                                        <TableCell>Наименование</TableCell>
                                                        <TableCell>Ед. измер.</TableCell>
                                                        <TableCell>Кол-во</TableCell>
                                                        <TableCell>Примечание</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {values.installation_items.map((item: InstallationItem, index: number) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>
                                                                <Autocomplete
                                                                    sx={{ width: '300px' }}
                                                                    size={'small'}
                                                                    options={inventory}
                                                                    value={inventory.find(option => String(option.id) === String(item.inventory_item_id)) || null}
                                                                    getOptionLabel={(option) => option.description || ''}
                                                                    onChange={(event, value) => {
                                                                        handleChange({
                                                                            target: {
                                                                                name: `installation_items[${index}].inventory_item_id`,
                                                                                value: value ? value.id : '',
                                                                            }
                                                                        });
                                                                        handleChange({
                                                                            target: {
                                                                                name: `installation_items[${index}].unit`,
                                                                                value: value ? value.unit : '',
                                                                            }
                                                                        });
                                                                    }}
                                                                    isOptionEqualToValue={(option, value) => String(option.id) === String(value?.id)}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            label="Наименование"
                                                                            fullWidth
                                                                        />

                                                                    )}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Field
                                                                    name={`installation_items[${index}].unit`}
                                                                    as={TextField}
                                                                    label="Ед. измерения"
                                                                    size={'small'}
                                                                    value={inventory.find(option => String(option.id) === String(item.inventory_item_id))?.unit || ''}
                                                                    fullWidth
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Field
                                                                    name={`installation_items[${index}].quantity`}
                                                                    type={'number'}
                                                                    size={'small'}
                                                                    as={TextField}
                                                                    label="Кол-во"
                                                                    fullWidth
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Field
                                                                    name={`installation_items[${index}].comments`}
                                                                    as={TextField}
                                                                    size={'small'}
                                                                    label="Примечание"
                                                                    fullWidth
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <IconButton
                                                                    onClick={() => remove(index)}
                                                                    color="error"
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                            <Button
                                                variant="contained"
                                                onClick={() => push({ inventory_item_id: '', unit: '', quantity: '', comments: '' })}
                                            >
                                                Добавить ТМЦ
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </FieldArray>
                            {/* Save Button */}
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 4 }}>
                                <Button type="submit" variant="contained" color="primary">
                                    Сохранить
                                </Button>
                                <Button onClick={onClose} variant="contained" color="secondary">
                                    Отмена
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
                <Button onClick={handleDownload} variant="contained" color="primary" size={'large'} sx={{mb:1}}>Скачать документ</Button>
            </Box>
        </Modal>
    );
};

export default InstallationActModal;
