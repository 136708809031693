import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
import { baseApiUrl } from './baseUrls';
import {AxiosResponse} from "axios/index";

class API {
    protected instance(auth = true, url?: string): AxiosInstance {
        const axiosInstance = axios.create({
            baseURL: url || baseApiUrl,
        });

        if (auth) {
            axiosInstance.interceptors.request.use(
                async (config) => {
                    if (config.headers) {
                        const token = Cookies.get('access_token');
                        if (token) {
                            config.headers.Authorization = `Bearer ${token}`;
                        }
                    }
                    return config;
                },
                (error) => Promise.reject(error)
            );
        }

        axiosInstance.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;
                if ((error.response?.status === 401 || error.response?.status === 403) && !originalRequest._retry) {
                    originalRequest._retry = true;
                    const refreshToken = Cookies.get('refresh_token');

                    if (refreshToken) {
                        try {
                            const response = await this.refreshToken(refreshToken);
                            Cookies.set('access_token', response.data.access_token);
                            originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;
                            return axios(originalRequest);
                        } catch (error) {
                            // Handle token refresh failure
                            this.handleTokenExpiry();
                        }
                    } else {
                        // No refresh token, handle logout
                        this.handleTokenExpiry();
                    }
                }
                return Promise.reject(error);
            }
        );

        return axiosInstance;
    }

    private async refreshToken(refreshToken: string) {
        return axios.post(`${baseApiUrl}/auth/refresh`, {
            refresh_token: refreshToken,
        });
    }

    private handleTokenExpiry() {
        Cookies.remove('access_token');
        Cookies.remove('refresh_token');
        window.location.href = '/login'; // Redirect to login page
    }

    get<T>(url: string, config?: AxiosRequestConfig) {
        return this.instance()
            .get<T>(url, config)
            .then((response) => response.data);
    }

    post<T, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig) {
        return this.instance()
            .post<T>(url, data, config)
            .then((response) => response.data);
    }
    delete<T, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig) {
        return this.instance()
            .delete<T>(url, { ...config, data })
            .then((response) => response.data);
    }
    put<T, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig) {
        return this.instance()
            .put<T>(url, data, config)
            .then((response) => response.data);
    }
    getBlob(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<Blob>> {
        return this.instance()
            .get<Blob>(url, { ...config, responseType: 'blob' });
    }
}

export default API;
