// DriversMain.tsx
import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Box
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { IDriver, DriversApi } from '../../api/driversApi';
import DriverForm from "./DriversForm";


const DriversMain: React.FC = () => {
    const driverApi = new DriversApi();
    const [drivers, setDrivers] = useState<IDriver[]>([]);
    const [open, setOpen] = useState(false);
    const [currentDriver, setCurrentDriver] = useState<IDriver | null>(null);

    const fetchDrivers = async () => {
        const data = await driverApi.getDrivers();
        setDrivers(data);
    };

    const handleAdd = () => {
        setCurrentDriver(null);
        setOpen(true);
    };

    const handleEdit = (driver: IDriver) => {
        setCurrentDriver(driver);
        setOpen(true);
    };

    const handleDelete = async (id: number) => {
        await driverApi.deleteDriver(id);
        fetchDrivers();
    };

    const handleSubmit = async (data: Omit<IDriver, "id">) => {
        if (currentDriver) {
            await driverApi.editDriver({ ...currentDriver, ...data });
        } else {
            await driverApi.postDriver(data);
        }
        setOpen(false);
        fetchDrivers();
    };

    useEffect(() => {
        fetchDrivers();
    }, []);

    return (
        <div>
            <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button variant="contained" color="primary" onClick={handleAdd}>
                    Добавить водителя
                </Button>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Имя</TableCell>
                        <TableCell>Фамилия</TableCell>
                        <TableCell>Лицензии</TableCell>
                        <TableCell>Действия</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {drivers?.map((driver) => (
                        <TableRow key={driver.id}>
                            <TableCell>{driver.name}</TableCell>
                            <TableCell>{driver.surname}</TableCell>
                            <TableCell>{driver.licenses.join(', ')}</TableCell>
                            <TableCell>
                                <IconButton onClick={() => handleEdit(driver)} color="primary">
                                    <Edit />
                                </IconButton>
                                <IconButton onClick={() => handleDelete(driver.id)} color="secondary">
                                    <Delete />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{currentDriver ? 'Редактировать водителя' : 'Добавить водителя'}</DialogTitle>
                <DialogContent>
                    <DriverForm
                        initialData={currentDriver as IDriver}
                        onSubmit={handleSubmit}
                        onCancel={() => setOpen(false)}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default DriversMain;
