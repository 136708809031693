import { useState } from 'react';
import axios from 'axios';

interface DownloadOptions {
    apiCall: () => Promise<any>;  // API call that returns the file
}

export const useFileDownload = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const downloadFile = async ({ apiCall }: DownloadOptions) => {
        setLoading(true);
        setError(null);

        try {
            // Call the API and receive the file
            const response = await apiCall();

            // Extract filename from the Content-Disposition header
            const contentDisposition = response.headers['content-disposition'];
            let filename = 'downloaded_file';  // Fallback filename

            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)/);
                if (filenameMatch && filenameMatch[1]) {
                    filename = decodeURIComponent(filenameMatch[1]);
                }
            }

            // Create a Blob object and download the file
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;  // Use the extracted or default filename
            document.body.appendChild(link);
            link.click();

            // Cleanup the URL object
            URL.revokeObjectURL(url);
            link.remove();
        } catch (err) {
            setError('Failed to download file.');
            console.error('Error downloading file:', err);
        } finally {
            setLoading(false);
        }
    };

    return {
        downloadFile,
        loading,
        error,
    };
};
