import React from 'react';
import {Box, Typography} from "@mui/material";

const NotFoundPage = () => {
    return (
        <Box sx={{ textAlign: 'center', mt: 5 }}>
            <Typography variant="h3" component="h1">
                404 - Page Not Found
            </Typography>
            <Typography variant="h5" component="p">
                The page you are looking for does not exist.
            </Typography>
        </Box>
    );
};

export default NotFoundPage;