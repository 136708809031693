// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_mileage__7qn7d {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.styles_actions__flOas {
  display: flex;
  align-items: center;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/VehiclesPage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AAEF","sourcesContent":[".mileage {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.actions{\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mileage": `styles_mileage__7qn7d`,
	"actions": `styles_actions__flOas`
};
export default ___CSS_LOADER_EXPORT___;
