import React, {useEffect, useState} from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper, Link, TextField, Avatar, MenuItem, Select, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions,
} from '@mui/material';
import { Image as ImageIcon } from '@mui/icons-material';
import {IVehicle} from "../../../../api/authApi";
import {VehiclesApi} from "../../../../api/vehiclesApi";
import SectionDivider from "./SectionDivider";
import {DriversApi, IDriver} from "../../../../api/driversApi";
import {useParams} from "react-router-dom";
import { toast } from 'react-toastify';
import ClearIcon from '@mui/icons-material/Clear';
import {useAppContext} from "../../../store/AppContext";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {Dayjs} from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {MaintenanceApi} from "../../../../api/maintenanceApi";

export interface ILeftSideInfo {
    data: IVehicle;
    driverOptions: IDriver[];
    getDrivers: () => unknown;
}
const LeftSideInfo: React.FC<ILeftSideInfo> = ({data, driverOptions, getDrivers}) => {
    const vehicleApi = new VehiclesApi();
    const driversApi = new DriversApi();
    const [selectedDriver, setSelectedDriver] = useState<number | string>('');
    const [imageUrl, setImageUrl] = useState<string>('');
    const {setDriverId} = useAppContext();

    const {id} = useParams();
    const handleGetVehicleDriver = async () => {
        try {
            const response = await driversApi.getActiveDriverByVehicle(Number(id));
            setSelectedDriver(response[0].driver_id);
            setDriverId(response[0].driver_id);
        } catch (error) {
            console.error('Error fetching vehicle driver:', error);
        }
    };

    const handleDriverChange = (event: any) => {
        setSelectedDriver(event.target.value);
    };
    const handleSave = async () => {
        try {
            await vehicleApi.assignDriverToVehicle(data?.id as number, Number(selectedDriver));
            setDriverId(selectedDriver);
            toast.success('Водитель успешно привязан');
            await getDrivers();
            await handleGetVehicleDriver();
        } catch (error: any) {
            toast.error(error.response.data.detail);
        }
    };
    const handleUnassignDriver = async () =>{
        try {
            await vehicleApi.deleteDriverFromVehicle(data?.id as number, Number(selectedDriver));
            toast.success('Водитель успешно отвязан')
            await getDrivers();
            await handleGetVehicleDriver();
            setSelectedDriver('');
            setDriverId('');
        } catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await vehicleApi.getFrontImage(Number(id));
                if (response && response.data) {
                    const imageBlob = response.data;
                    const imageObjectURL = URL.createObjectURL(imageBlob);
                    setImageUrl(imageObjectURL);
                }
            } catch (error: any) {
                console.error('Error fetching the image:', error);
            }
        }

        fetchData();

        return () => {
            if (imageUrl) {
                URL.revokeObjectURL(imageUrl);
            }
        };
    }, []);

    useEffect(() => {
        handleGetVehicleDriver();
    }, []);

    const renderValue = (selected: any) => {
        const selectedOption = driverOptions.find(option => option.id === selected);
        return selectedOption ? `${selectedOption.name} ${selectedOption.surname}` : '';
    };
    // download components here

    const [open, setOpen] = useState(false);
    const [dateFrom, setDateFrom] = useState<Dayjs | null>(null);
    const [dateTo, setDateTo] = useState<Dayjs | null>(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDownload = async () => {
        try {
            const formattedDateFrom = dateFrom ? dateFrom.format('YYYY-MM-DD') : null;
            const formattedDateTo = dateTo ? dateTo.format('YYYY-MM-DD') : null;
            if (!formattedDateFrom || !formattedDateTo) {
                toast.error("Выберите обе даты");
                return;
            }
            const blob = await vehicleApi.getTrackingDocument(Number(id), formattedDateFrom, formattedDateTo);
            const url = window.URL.createObjectURL(new Blob([blob.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'tracking.xlsx');
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (error) {
            console.error('Download failed', error);
        }
    };


    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 2,
                }}
            >
                {imageUrl ? (
                    <img
                        src={imageUrl}
                        alt="Vehicle"
                        style={{
                            width: '100%',
                            maxWidth: '300px',
                            height: 'auto',
                            maxHeight: '300px',
                            objectFit: 'contain',
                        }}
                    />
                ) : (
                    <Avatar
                        variant="square"
                        sx={{
                            width: 300,
                            height: 200,
                            backgroundColor: 'grey.300',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <ImageIcon sx={{fontSize: 50}}/>
                    </Avatar>
                )}
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Водитель</TableCell>
                            <TableCell>
                                <Box sx={{display: 'flex', alignItems: 'center'}}>
                                    <Select
                                        value={selectedDriver}
                                        onChange={handleDriverChange}
                                        displayEmpty
                                        fullWidth
                                        sx={{height: 40}}
                                        renderValue={renderValue}
                                    >
                                        {driverOptions?.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                <Box
                                                    sx={{
                                                        width: 10,
                                                        height: 10,
                                                        borderRadius: '50%',
                                                        backgroundColor: option.available ? 'green' : 'red',
                                                        marginRight: 1,
                                                    }}
                                                />
                                                {`${option.name} ${option.surname}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Button
                                        variant="contained"
                                        onClick={handleSave}
                                        sx={{marginLeft: 2, height: 40, fontSize: '12px'}}
                                    >
                                        Привязать
                                    </Button>
                                    <IconButton onClick={handleUnassignDriver}>
                                        <ClearIcon/>
                                    </IconButton>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Гаражный номер</TableCell>
                            <TableCell align="right">{data?.garage_number}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Модель</TableCell>
                            <TableCell align="right">{data?.model}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Категория</TableCell>
                            <TableCell align="right">{data?.license_category}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Тип Автомобиля</TableCell>
                            <TableCell align="right">{data?.vehicle_type}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <SectionDivider text={'Характеристики'}/>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Ширина</TableCell>
                            <TableCell align="right">{data?.width}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Высота</TableCell>
                            <TableCell align="right">{data?.height}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Длина</TableCell>
                            <TableCell align="right">{data?.length}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Масса</TableCell>
                            <TableCell align="right">{data?.mass_unloaded}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Цвет</TableCell>
                            <TableCell align="right">{data?.color}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <SectionDivider text={'Идентификатор'}/>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Год выпуска</TableCell>
                            <TableCell align="right">{data?.year}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Регистрационный номер</TableCell>
                            <TableCell align="right">{data?.gos_number}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Номер техпаспорта</TableCell>
                            <TableCell align="right">{data?.tech_passport_number}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>VIN</TableCell>
                            <TableCell align="right">{data?.serial_number}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {/*button here*/}
            <div style={{paddingTop: '8px'}}>
                <Button variant="contained" onClick={handleClickOpen}>
                    Километражное хозяйство
                </Button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Выберите диапазон дат</DialogTitle>
                    <DialogContent>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, mt: 1}}>
                                <DatePicker
                                    label="Дата начала"
                                    value={dateFrom}
                                    onChange={(newValue) => setDateFrom(newValue)}
                                />
                                <DatePicker
                                    label="Дата окончания"
                                    value={dateTo}
                                    onChange={(newValue) => setDateTo(newValue)}
                                />
                            </Box>
                        </LocalizationProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} sx={{color: 'grey.600'}}>Отмена</Button>
                        <Button onClick={handleDownload} variant="contained" sx={{backgroundColor: '#6A1B9A'}}>
                            Скачать
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Box>
    );
};

export default LeftSideInfo;
