import React, {useEffect, useState} from 'react';
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button,
    Grid,
} from '@mui/material';
import { Formik, Field, Form } from 'formik';
import {RepairApi} from "../../../../api/repairApi";
import {useFileDownload} from "../../../../hooks/useFileDownload";

interface DefectActModalProps {
    open: boolean;
    onClose: () => void;
    marka: string;
    mileage: string;
    gos_number: string;
    defectForm: IDefectReportForm;
}
export interface IDefectReportForm {
    city: string;
    department: string;
    applicationDate: string;
    defects: string;
    partName: string;
    reason: string;
    solution: string;
    executionDate: string;
    defectFixDate: string;
    requestNumber: string;
    actNumber: string;
    repair_work_item_id?: string;
}

const DefectActModal: React.FC<DefectActModalProps> = ({ open, onClose, marka, mileage, gos_number , defectForm}) => {
    const repairApi = new RepairApi();
    const { downloadFile, loading, error } = useFileDownload();
    const handleSubmitValues = async (values: IDefectReportForm) => {
        await repairApi.putDefectReport(defectForm.repair_work_item_id as string, values);
        onClose();
    }
    useEffect(() => {
        console.log('test')
    }, []);

    const handleDownload = async () => {
        downloadFile({
            apiCall: () => repairApi.downloadDefectRepairDoc(defectForm?.repair_work_item_id as string),
        });
    };

    return (
        <Modal open={open} onClose={onClose} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Box
                sx={{
                    overflow: 'auto',
                    m: 4,
                    backgroundColor: 'background.paper',
                    border: '1px solid #000',
                    borderRadius: '4px',
                    boxShadow: 24,
                    p: 4,
                    width: '75%',
                    maxWidth: '1080px',
                    maxHeight: '90vh',
                }}
            >
                <Formik
                    <IDefectReportForm>
                    initialValues={defectForm}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        handleSubmitValues(values)
                    }}
                >
                    {({ values, handleChange }) => (
                        <Form>
                            <Typography variant="h6" gutterBottom display={'flex'} justifyContent={'flex-start'}>
                                Дефектная ведомость № {defectForm?.repair_work_item_id}
                            </Typography>

                            {/* City, Department, Date Fields */}
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={4}>
                                    <Field
                                        name="city"
                                        as={TextField}
                                        label="Город"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        name="department"
                                        as={TextField}
                                        label="Подразделение"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        name="applicationDate"
                                        as={TextField}
                                        label="Дата заявления"
                                        size="small"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            {/* Car Info */}
                            <Typography variant="body1" gutterBottom>
                                Наименование ТС: <strong>{marka}</strong>
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Показания спидометра/моточасы: <strong>{mileage}km</strong>
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                ГРНЗ: <strong>{gos_number}</strong>
                            </Typography>

                            {/* Defects TextArea */}
                            <Typography variant="body1" gutterBottom fontWeight={'bold'}>
                                Произвели осмотр и выявили следующие неисправности:
                            </Typography>
                            <Field
                                name="defects"
                                as={TextField}
                                placeholder="При осмотре выявили следующие неисправности..."
                                multiline
                                rows={3}
                                fullWidth
                                sx={{ mb: 2 }}
                            />

                            {/* Part Name */}
                            <Typography variant="body1" gutterBottom fontWeight={'bold'}>
                                Наименование узла, агрегата, механизма:
                            </Typography>
                            <Field
                                name="partName"
                                as={TextField}
                                placeholder="КПП, Сцепление, ..."
                                fullWidth
                                sx={{ mb: 2 }}
                            />

                            {/* Reason TextArea */}
                            <Typography variant="body1" gutterBottom fontWeight={'bold'}>
                                Причина возникновения данной неисправности:
                            </Typography>
                            <Field
                                name="reason"
                                as={TextField}
                                placeholder="Указать причины..."
                                multiline
                                rows={3}
                                fullWidth
                                sx={{ mb: 2 }}
                            />

                            {/* Solution TextArea */}
                            <Typography variant="body1" gutterBottom fontWeight={'bold'}>
                                Способ ее устранения:
                            </Typography>
                            <Field
                                name="solution"
                                as={TextField}
                                placeholder="Указать способы устранения..."
                                multiline
                                rows={3}
                                fullWidth
                                sx={{ mb: 2 }}
                            />

                            {/* Dates and Numbers */}
                            <Grid container spacing={2} sx={{ mb: 2 }} alignItems={'center'} >
                                {/* Date Fields in one row */}
                                <Grid item>
                                    <Typography variant="body1" fontWeight="bold">
                                        Дата исполнения заявки:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Field
                                        name="executionDate"
                                        as={TextField}
                                        size="small"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid item>
                                    <Typography variant="body1" fontWeight="bold">
                                        Дата устранения неисправности:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Field
                                        name="defectFixDate"
                                        as={TextField}
                                        size="small"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            {/* Request and Act Number in the next row */}
                            <Grid container spacing={2}  alignItems={'center'} mb={2}>
                                <Grid item>
                                    <Typography variant="body1" fontWeight="bold">
                                        № Заявки:
                                    </Typography>
                                </Grid>
                                <Grid item alignItems={'center'}>
                                    <Field
                                        name="requestNumber"
                                        size="small"
                                        as={TextField}
                                    />
                                </Grid>

                                <Grid item>
                                    <Typography variant="body1" fontWeight="bold">
                                        Акт на списание:
                                    </Typography>
                                </Grid>
                                <Grid item >
                                    <Field
                                        name="actNumber"
                                        as={TextField}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                            {/* Submit Button */}
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Сохранить
                            </Button>
                            <Button onClick={handleDownload} variant="contained" color="primary" sx={{ml: 1}}>Скачать документ</Button>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
};

export default DefectActModal;
