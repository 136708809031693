import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    marka: Yup.string().required('Обязательное поле'),
    model: Yup.string().required('Обязательное поле'),
    vehicle_type_id: Yup.number().nullable().required('Обязательное поле'),
    color: Yup.string().required('Обязательное поле'),
    year: Yup.number()
        .nullable()
        .required('Обязательное поле')
        .min(1900, 'Год должен быть между 1900 и 2024')
        .max(2024, 'Год должен быть между 1900 и 2024'),
    license_category: Yup.string().required('Обязательное поле'),
    gos_number: Yup.string().required('Обязательное поле'),
    serial_number: Yup.string().required('Обязательное поле'),
    tech_passport_number: Yup.string()
        .required('Обязательное поле')
        .matches(/^[A-Z]{2}\d{8}$/, 'Неверный формат. Формат должен быть AA00000000'),
    garage_number: Yup.string().required('Обязательное поле'),
    num_of_seats: Yup.number().nullable().required('Обязательное поле').min(0, 'Значение не может быть отрицательным'),
    mass_unloaded: Yup.number().nullable().required('Обязательное поле').min(0, 'Значение не может быть отрицательным'),
    lifting_capacity: Yup.number().nullable().required('Обязательное поле').min(0, 'Значение не может быть отрицательным'),
    axis_number: Yup.number().nullable().required('Обязательное поле').min(0, 'Значение не может быть отрицательным'),
    height: Yup.number().nullable().required('Обязательное поле').min(0, 'Значение не может быть отрицательным'),
    length: Yup.number().nullable().required('Обязательное поле').min(0, 'Значение не может быть отрицательным'),
    width: Yup.number().nullable().required('Обязательное поле').min(0, 'Значение не может быть отрицательным'),
    wheel_size: Yup.number().nullable().required('Обязательное поле').min(0, 'Значение не может быть отрицательным'),
    mileage: Yup.number().nullable().required('Обязательное поле').min(0, 'Значение не может быть отрицательным'),
    moto_hours: Yup.number().nullable().required('Обязательное поле').min(0, 'Значение не может быть отрицательным'),
    condition: Yup.string().required('Обязательное поле'),
    condition_comments: Yup.string().required('Обязательное поле'),
});