import React from 'react';
import { Container as MuiContainer, ContainerProps } from '@mui/material';

const CommonContainer: React.FC<ContainerProps> = ({ children, ...props }) => {
    return (
        <MuiContainer maxWidth="xl" {...props}>
            {children}
        </MuiContainer>
    );
};

export default CommonContainer;
