import React from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import { Box, List, ListItemText, ListItemIcon, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InventoryIcon from '@mui/icons-material/Inventory';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NoteIcon from '@mui/icons-material/Note';
import { styled } from '@mui/material/styles';
import Cookies from "js-cookie";
import CarRepairIcon from '@mui/icons-material/CarRepair';
import ContactPageIcon from '@mui/icons-material/ContactPage';

const NavItem = styled(NavLink)(({ theme }) => ({
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    width: '100%',
    '&.active': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
    },
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: '#fff',
    },
}));

const Sidebar: React.FC = () => {
    const navigate = useNavigate();
    const handleLogout = () => {
        Cookies.remove('access_token');
        Cookies.remove('refresh_token');
        navigate('/login');
    };
    return (
        <Box
            sx={{
                width: 240,
                height: '100vh',
                backgroundColor: '#2f3e46',
                color: '#fff',
                position: 'fixed',
            }}
        >
            <Box sx={{ p: 2 }}>
                <Typography variant="h5" sx={{ color: '#fff' }}>
                    PSI Service
                </Typography>
            </Box>
            <List>
                <div style={{pointerEvents: 'none'}}>
                    <NavItem to="/home" className={({isActive}) => (isActive ? 'active' : '')}>
                        <ListItemIcon sx={{color: 'gray'}}>
                            <HomeIcon/>
                        </ListItemIcon>
                        <ListItemText sx={{color: 'gray'}} primary="Главная"/>
                    </NavItem>
                </div>
                <NavItem to="/vehicles" className={({isActive}) => (isActive ? 'active' : '')}>
                    <ListItemIcon sx={{color: '#fff'}}>
                        <DirectionsCarIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Подвижной состав"/>
                </NavItem>
                <NavItem to="/maintenance" className={({isActive}) => (isActive ? 'active' : '')}>
                    <ListItemIcon sx={{color: '#fff'}}>
                        <CalendarTodayIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Планирование ТО"/>
                </NavItem>
                <NavItem to="/inventory" className={({isActive}) => (isActive ? 'active' : '')}>
                    <ListItemIcon sx={{color: '#fff'}}>
                        <InventoryIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Склад"/>
                </NavItem>
                <NavItem to="/templates" className={({isActive}) => (isActive ? 'active' : '')}>
                    <ListItemIcon sx={{color: '#fff'}}>
                        <NoteIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Шаблоны расходников"/>
                </NavItem>
                <NavItem to="/vehicle_types" className={({isActive}) => (isActive ? 'active' : '')}>
                    <ListItemIcon sx={{color: '#fff'}}>
                        <CarRepairIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Типы ТС"/>
                </NavItem>
                <NavItem to="/drivers" className={({isActive}) => (isActive ? 'active' : '')}>
                    <ListItemIcon sx={{color: '#fff'}}>
                        <ContactPageIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Водители"/>
                </NavItem>
                <div onClick={handleLogout}>
                    <NavItem to='/'>
                    <ListItemIcon sx={{color: '#fff'}}>
                        <ExitToAppIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Выйти"/>
                    </NavItem>
                </div>
            </List>
        </Box>
    );
};

export default Sidebar;
