import React, { useEffect, useState } from 'react';
import { VehiclesApi, IVehicleType } from '../../api/vehiclesApi';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    SelectChangeEvent, IconButton,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';

const VehicleTypesMain = () => {
    const vehicleApi = new VehiclesApi();

    const [vehicleTypes, setVehicleTypes] = useState<IVehicleType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [selectedVehicleType, setSelectedVehicleType] = useState<IVehicleType | null>(null);
    const [formState, setFormState] = useState<Omit<IVehicleType, 'id'>>({
        name: '',
        special_fields: {},
        default_tracking_type: 'Моточасы',
        default_tracking_interval_for_maintenance_to1: 0,
        default_tracking_interval_for_maintenance_to2: 0,
    });
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [vehicleTypeToDelete, setVehicleTypeToDelete] = useState<IVehicleType | null>(null);

    useEffect(() => {
        const fetchVehicleTypes = async () => {
            setLoading(true);
            try {
                const types = await vehicleApi.getVehicleTypes();
                setVehicleTypes(types);
            } catch (error) {
                console.error("Не удалось получить типы транспортных средств", error);
            }
            setLoading(false);
        };

        fetchVehicleTypes();
    }, []);

    const handleOpenModal = (vehicleType?: IVehicleType) => {
        if (vehicleType) {
            setSelectedVehicleType(vehicleType);
            setFormState(vehicleType);
            setEditMode(true);
        } else {
            setSelectedVehicleType(null);
            setFormState({
                name: '',
                special_fields: {},
                default_tracking_type: 'Моточасы',
                default_tracking_interval_for_maintenance_to1: 0,
                default_tracking_interval_for_maintenance_to2: 0,
            });
            setEditMode(false);
        }
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormState((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        setFormState((prev) => ({
            ...prev,
            [name as string]: value,
        }));
    };

    const handleFormSubmit = async () => {
        try {
            if (editMode && selectedVehicleType) {
                await vehicleApi.editVehicleTypeById(selectedVehicleType.id, formState);
            } else {
                await vehicleApi.addVehicleType(formState);
            }
            const types = await vehicleApi.getVehicleTypes();
            setVehicleTypes(types);
            setModalOpen(false);
        } catch (error) {
            console.error("Не удалось отправить форму", error);
        }
    };

    const handleOpenDeleteModal = (vehicleType: IVehicleType) => {
        setVehicleTypeToDelete(vehicleType);
        setDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalOpen(false);
        setVehicleTypeToDelete(null);
    };

    const handleConfirmDelete = async () => {
        if (vehicleTypeToDelete) {
            try {
                await vehicleApi.deleteVehicleTypeById(vehicleTypeToDelete.id);
                const types = await vehicleApi.getVehicleTypes();
                setVehicleTypes(types);
            } catch (error) {
                console.error("Не удалось удалить тип транспортного средства", error);
            } finally {
                handleCloseDeleteModal();
            }
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '10px' }}>
                <Button variant="contained" color="primary" onClick={() => handleOpenModal()}>
                    Добавить
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Название</TableCell>
                            <TableCell>Тип отслеживания</TableCell>
                            <TableCell>Интервал отслеживания ТО1</TableCell>
                            <TableCell>Интервал отслеживания ТО2</TableCell>
                            <TableCell>Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {vehicleTypes.map((type) => {
                            const trackingIntervalUnit = type.default_tracking_type === 'Пробег' ? 'км' : 'м/ч';
                            return (
                                <TableRow key={type.id}>
                                    <TableCell>{type.name}</TableCell>
                                    <TableCell>{type.default_tracking_type}</TableCell>
                                    <TableCell>{`${type.default_tracking_interval_for_maintenance_to1} ${trackingIntervalUnit}`}</TableCell>
                                    <TableCell>{`${type.default_tracking_interval_for_maintenance_to2} ${trackingIntervalUnit}`}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleOpenModal(type)}
                                            style={{ marginRight: '10px' }}
                                        >
                                            <Edit />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleOpenDeleteModal(type)}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={modalOpen} onClose={handleCloseModal}>
                <DialogTitle>{editMode ? 'Редактировать тип транспортного средства' : 'Добавить тип транспортного средства'}</DialogTitle>
                <DialogContent>
                    <TextField
                        name="name"
                        label="Название"
                        value={formState.name}
                        onChange={handleInputChange}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                    />
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel>Тип отслеживания</InputLabel>
                        <Select
                            name="default_tracking_type"
                            value={formState.default_tracking_type}
                            onChange={handleSelectChange}
                            label="Тип отслеживания"
                        >
                            <MenuItem value="Моточасы">Моточасы</MenuItem>
                            <MenuItem value="Пробег">Пробег</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        name="default_tracking_interval_for_maintenance_to1"
                        label={`Интервал отслеживания ТО1 (${formState.default_tracking_type === 'Пробег' ? 'км' : 'м/ч'})`}
                        type="number"
                        value={formState.default_tracking_interval_for_maintenance_to1}
                        onChange={handleInputChange}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                    />
                    <TextField
                        name="default_tracking_interval_for_maintenance_to2"
                        label={`Интервал отслеживания ТО2 (${formState.default_tracking_type === 'Пробег' ? 'км' : 'м/ч'})`}
                        type="number"
                        value={formState.default_tracking_interval_for_maintenance_to2}
                        onChange={handleInputChange}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        {editMode ? 'Обновить' : 'Создать'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deleteModalOpen} onClose={handleCloseDeleteModal}>
                <DialogTitle>Подтверждение удаления</DialogTitle>
                <DialogContent>
                    Вы уверены, что хотите удалить тип транспортного средства "{vehicleTypeToDelete?.name}"?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteModal} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary">
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default VehicleTypesMain;
