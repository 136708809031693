import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Select,
    MenuItem,
    TextField,
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { Add, Edit, Delete, Save } from '@mui/icons-material';
import { useFormik } from 'formik';
import styles from './styles.module.scss';
import { ICategory, IInventory, InventoryApi } from '../../api/inventoryApi';

const TableComponent: React.FC = () => {
    const inventoryApi = new InventoryApi();
    const [inventory, setInventory] = useState<IInventory[]>([]);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [isEditing, setIsEditing] = useState<number | null>(null);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState<number | null>(null);

    const fetchInventory = async () => {
        const data = await inventoryApi.getInventory();
        setInventory(data);
    };

    const fetchCategories = async () => {
        const data = await inventoryApi.getCategories();
        setCategories(data);
    };

    const handleDelete = async () => {
        if (deleteItemId !== null) {
            await inventoryApi.deleteInventory(deleteItemId);
            fetchInventory();
            setDeleteItemId(null);
            setIsDeleteConfirmVisible(false);
        }
    };

    const handleAdd = () => {
        formik.resetForm();
        setIsEditing(null);
        setIsFormVisible(true);
    };

    const handleEdit = (item: IInventory) => {
        formik.setValues(item);
        setIsEditing(item.id);
        setIsFormVisible(true);
    };

    const getCategoryNameById = (id: number) => {
        const category = categories.find((category) => category.id === id);
        return category ? category.name : 'Unknown';
    };

    const formik = useFormik<IInventory>({
        initialValues: {
            id: 0,
            name: '',
            description: '',
            category_id: 0,
            warehouse_id: 1,
            quantity: 0,
            volume: 0,
            condition: '',
            comments: '',
            unit: ''
        },
        onSubmit: async (values) => {
            if (isEditing !== null) {
                await inventoryApi.editInventory(isEditing, values);
            } else {
                await inventoryApi.postInventory(values);
            }
            fetchInventory();
            formik.resetForm();
            setIsEditing(null);
            setIsFormVisible(false);
        },
    });

    useEffect(() => {
        fetchInventory();
        fetchCategories();
    }, []);
    const units = ['шт', 'кг', 'л', 'м'];

    return (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Имя</TableCell>
                            <TableCell>Описание</TableCell>
                            <TableCell>Категория</TableCell>
                            <TableCell>Количество</TableCell>
                            <TableCell>Объем</TableCell>
                            <TableCell>Состояние</TableCell>
                            <TableCell>Комментарии</TableCell>
                            <TableCell>Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {inventory?.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>{getCategoryNameById(item.category_id)}</TableCell>
                                <TableCell>{item.quantity}</TableCell>
                                <TableCell>{item.volume}</TableCell>
                                <TableCell>{item.condition}</TableCell>
                                <TableCell>{item.comments}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleEdit(item)} color="primary">
                                        <Edit />
                                    </IconButton>
                                    <IconButton onClick={() => { setDeleteItemId(item.id); setIsDeleteConfirmVisible(true); }} color="error">
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button onClick={handleAdd} startIcon={<Add />} variant="contained" color="primary" style={{ marginTop: '16px' }}>
                Добавить
            </Button>

            {/* Add/Edit Modal */}
            <Dialog open={isFormVisible} onClose={() => setIsFormVisible(false)}>
                <DialogTitle>{isEditing !== null ? 'Редактировать инвентарь' : 'Добавить инвентарь'}</DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <TextField
                            name="name"
                            label="Имя"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            className={styles.formControlWide}
                            margin="normal"
                            required
                        />
                        <TextField
                            name="description"
                            label="Описание"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            className={styles.formControlWide}
                            margin="normal"
                            required
                        />
                        <FormControl className={styles.formControlWide} margin="normal">
                            <Select
                                name="category_id"
                                value={formik.values.category_id}
                                onChange={formik.handleChange}
                                required
                            >
                                {categories?.map((category) => (
                                    <MenuItem key={category.id} value={category.id}>
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={styles.formControlWide} margin="normal">
                            <Select
                                name="unit"
                                value={formik.values.unit}
                                onChange={formik.handleChange}
                                required
                            >
                                {units?.map((unit) => (
                                    <MenuItem value={unit}>
                                        {unit}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            name="quantity"
                            label="Количество"
                            type="number"
                            value={formik.values.quantity}
                            onChange={formik.handleChange}
                            className={styles.formControlWide}
                            margin="normal"
                            required
                        />
                        <TextField
                            name="volume"
                            label="Объем"
                            type="number"
                            value={formik.values.volume}
                            onChange={formik.handleChange}
                            className={styles.formControlWide}
                            margin="normal"
                            required
                        />
                        <FormControl className={styles.formControlWide} margin="normal">
                            <Select
                                name="condition"
                                value={formik.values.condition}
                                onChange={formik.handleChange}
                                required
                            >
                                <MenuItem value="Новое">Новое</MenuItem>
                                <MenuItem value="Б/У">Б/У</MenuItem>
                                <MenuItem value="Утилизация">Утилизация</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            name="comments"
                            label="Комментарии"
                            value={formik.values.comments}
                            onChange={formik.handleChange}
                            className={styles.formControlWide}
                            margin="normal"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsFormVisible(false)} color="secondary">
                            Отмена
                        </Button>
                        <Button type="submit" startIcon={<Save />} variant="contained" color="primary">
                            Сохранить
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {/* Delete Confirmation Modal */}
            <Dialog
                open={isDeleteConfirmVisible}
                onClose={() => setIsDeleteConfirmVisible(false)}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы уверены, что хотите удалить этот предмет инвентаря?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDeleteConfirmVisible(false)} color="secondary">
                        Отмена
                    </Button>
                    <Button onClick={handleDelete} color="primary">
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default TableComponent;
