import API from "./api";
import {IPostConsumables} from "./vehiclesApi";

export interface ITemplate {
    id: number;
    template_name: string;
    consumable_type: string;
    replacement_part: string;
}
export interface IPostTemplate extends Omit<IPostConsumables, 'replacement_part'>{
    template_name: string;
    id: number | null;
    replacement_part_id: number | null;
}
export class TemplatesApi extends API {
    getTemplates(): Promise<ITemplate[]> {
        return this.get(`api/vehicle_consumables_templates`);
    }
    getTemplatesWithId(id: number): Promise<ITemplate[]> {
        return this.get(`api/vehicle_consumables_templates?consumable_type_id=${id}`);
    }
    createTemplate(data: Omit<IPostTemplate, 'id'>): Promise<IPostTemplate> {
        return this.post(`api/vehicle_consumables_templates`, data);
    }
    getTemplateById(templateId: number): Promise<IPostTemplate> {
        return this.get(`api/vehicle_consumables_templates/${templateId}`);
    }
    updateTemplate(templateId: number, data: IPostTemplate): Promise<IPostTemplate> {
        return this.put(`api/vehicle_consumables_templates/${templateId}`, data);
    }
    deleteTemplate(templateId: number) {
        return this.delete(`api/vehicle_consumables_templates/${templateId}`);
    }
}