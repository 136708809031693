import React, {useEffect, useState} from 'react';
import {Box, Button, Container, Grid, IconButton} from '@mui/material';
import LeftSideInfo from "./components/LeftSideInfo";
import RightSideInfo from "./components/RightSideInfo";
import {IPostConsumables, VehiclesApi} from "../../../api/vehiclesApi";
import {useNavigate, useParams} from "react-router-dom";
import {IVehicle} from "../../../api/authApi";
import {DriversApi, IDriver} from "../../../api/driversApi";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {toast} from "react-toastify";
const VehicleInfo: React.FC = () => {
    const vehicleApi = new VehiclesApi();
    const driversApi = new DriversApi();

    const {id} = useParams();
    const [vehicle, setVehicle] = useState<IVehicle | null>(null)
    const [consumables, setConsumables] = useState<IPostConsumables[]>([]);
    const [driverOptions, setDriverOptions] = useState<IDriver[]>([]);
    const navigate = useNavigate();
    const handleGetDrivers = async () => {
        try {
            const response = await driversApi.getDrivers();
            setDriverOptions(response);
        } catch (error) {
            console.error('Error fetching drivers:', error);
        }
    };
    const handleGetVehicleInfo = async () => {
        try {
            const data = await vehicleApi.getVehicleById(Number(id));
            setVehicle(data);
        } catch (e) {
            console.log(e);
        }
    }
    const handleGetConsumables = async () => {
        try {
            const data = await vehicleApi.getConsumablesById(Number(id));
            setConsumables(data);
        } catch (e) {
            console.log(e);
        }
    }
    const handleChangeVehicleStatus = async () => {
        try {
            const data = await vehicleApi.changeVehicleStatus(Number(id), 'На ремонте');
            navigate(`/repair/${id}/${data.repair_card_id}`);
        } catch (e: any) {
            toast.error(e.response.data.detail);
        }
    }

    useEffect(() => {
        void handleGetDrivers();
        void handleGetVehicleInfo();
        void handleGetConsumables();
    }, []);

    return (
        <Container maxWidth={'xl'}>
            <Grid container spacing={3} my={2}>
                <Grid item xs={12} md={6}>
                    <Box mb={2} display="flex" alignItems="center" justifyContent={'space-between'}>
                        <IconButton onClick={() => navigate(-1)} sx={{ mr: 2 }}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Button
                            variant="contained"
                            onClick={() => {handleChangeVehicleStatus()}}
                            sx={{
                                backgroundColor: vehicle?.condition === 'На ремонте' ? 'orange' : 'primary.main',
                            }}
                        >{vehicle?.condition === 'На ремонте' ? 'На ремонте' : 'В ремонт'}</Button>
                    </Box>
                    <LeftSideInfo data={vehicle as IVehicle} driverOptions={driverOptions} getDrivers={handleGetDrivers}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <RightSideInfo
                        consumables={consumables}
                        vehicle={vehicle as IVehicle}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default VehicleInfo;
