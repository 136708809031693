import React from 'react';
import { Box } from '@mui/material';

interface VerticalSeparatorProps {
    height?: string; // Optional height prop, defaults to 100%
    color?: string; // Optional color prop, defaults to #ccc
    thickness?: string; // Optional thickness prop, defaults to 1px
}

const VerticalSeparator: React.FC<VerticalSeparatorProps> = ({
                                                                 height = '100%',
                                                                 color = '#ccc',
                                                                 thickness = '1px',
                                                             }) => {
    return (
        <Box
            sx={{
                height: height,
                width: thickness,
                backgroundColor: color,
            }}
        />
    );
};

export default VerticalSeparator;