import React from 'react';
import {Box, Button, Typography, Modal, Paper, IconButton} from '@mui/material';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
interface ModalMaintenanceProps {
    open: boolean;
    onClose: () => void;
    handlePlanned: () => void;
    handleActual: () => void;
    date: string;
}


const ModalMaintenance: React.FC<ModalMaintenanceProps> = ({ open, onClose, handlePlanned, date, handleActual }) => {

    return (
        <Modal open={open} onClose={onClose}>
            <Paper sx={{ p: 3, maxWidth: 400, margin: 'auto', mt: '10%', position: 'relative' }}>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" gutterBottom textAlign={'center'}>
                    Выберите вид ТО для даты: {date}
                </Typography>
                <Box display="flex" justifyContent="space-between" mt={2}>
                    <Button variant="contained" color="primary" onClick={handlePlanned}>
                        Плановое
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleActual}>
                        Фактическое
                    </Button>
                </Box>
            </Paper>
        </Modal>
    );
};

export default ModalMaintenance;
