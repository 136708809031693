import API from "./api";

export interface IDriver {
    name: string;
    surname: string;
    licenses: string[];
    id: number;
    available?: boolean;
}

export class DriversApi extends API {
    getDrivers():Promise<IDriver[]> {
        return this.get('api/drivers');
    }

    postDriver(data: Omit<IDriver, "id">) {
        return this.post('api/drivers', data);
    }
    editDriver(data: IDriver) {
        return this.put(`api/drivers/${data.id}`, data);
    }
    deleteDriver(id: number) {
        return this.delete(`api/drivers/${id}`);
    }
    getDriverByVehicle(vehicleId: number):Promise<{driver_id: number}[]> {
        return this.get(`api/vehicles/${vehicleId}/drivers`)
    }
    getActiveDriverByVehicle(vehicleId: number):Promise<{driver_id: number, driver_name: string}[]> {
        return this.get(`api/vehicles/${vehicleId}/drivers?only_active=true`)
    }
    getVehiclesByDriver(driverId: number){
        return this.get(`api/drivers/${driverId}/vehicles`)
    }
    downloadWayBill(vehicle_id: number, driver_id: number) {
        return this.getBlob(`api/vehicles/${vehicle_id}/drivers/${driver_id}/way_list_template`)
    }
}
