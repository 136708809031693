import React, { useState } from 'react';
import {
    Container,
    TextField,
    Button,
    Typography,
    Box,
    Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthAPI from "../../api/authApi";

const authAPI = new AuthAPI();

const LoginPage: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            await authAPI.login(username, password);
            navigate('/maintenance');
        } catch (error) {
            setError('Invalid username or password');
        }
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} sx={{ padding: 4, marginTop: 8 }}>
                <Box textAlign="center">
                    <Typography variant="h4" gutterBottom>
                        PSI Service
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Авторизация
                    </Typography>
                </Box>
                <TextField
                    label="Пользователь"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Пароль"
                    variant="outlined"
                    type="password"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
                {error && (
                    <Typography color="error" variant="body2">
                        {error}
                    </Typography>
                )}
                <Box textAlign="center" marginTop={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLogin}
                    >
                        Войти
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};

export default LoginPage;
