import API from "./api";
import {IVehicle} from "./authApi";
import {AxiosResponse} from "axios/index";

export interface IVehicleType{
    id: number;
    name: string;
    special_fields: {}
    default_tracking_type: string;
    default_tracking_interval_for_maintenance_to1: number;
    default_tracking_interval_for_maintenance_to2: number;
}
export interface IVehicleRequest {
    marka: string;
    model: string;
    vehicle_type_id: number | null;
    color: string;
    year: number | null;
    license_category: string;
    gos_number: string;
    serial_number: string;
    tech_passport_number: string;
    garage_number: string;
    num_of_seats: number | null;
    mass_unloaded: number | null;
    lifting_capacity: number | null;
    axis_number: number | null;
    height: number | null;
    length: number | null;
    width: number | null;
    wheel_size: number | null;
    mileage: number | null;
    moto_hours: number | null;
    condition: string;
    condition_comments: string;
    type_special_fields: Record<string, any>;
    doc_front_image: string;
    doc_registration: string;
    doc_tech_details: string;
    additionalInfo: string;
    id?: number;
    vehicle_type?: string;
}

export interface IConsumableTypes{
    id: number;
    interval_units: string[];
    name: string;
    unit: string;
}
export interface IPostConsumables{
    consumable_type_id: number | null;
    consumption_rate: number | null;
    consumption_unit: string;
    consumption_rate_calculation_interval: number | null;
    replacement_interval: number | null;
    replacement_interval_unit: string;
    replacement_part_id: number | null;
    vehicle_consumable_volume: number | null;
    template_id?: number | null;
}
export interface IMileageInfo{
    "id": number,
    "vehicle_id": number,
    "track_type": string,
    "track_amount": number,
    "work_date": string,
}
export interface IPostMileage{
    "track_type": string,
    "updated_track_record": number,
    "work_date": string,
}
export class VehiclesApi extends API {
    getVehicles():Promise<IVehicle[]> {
        return this.get('api/vehicles')
    }
    getVehicleTypes():Promise<IVehicleType[]>{
        return this.get('api/vehicle_types')
    }
    getConsumablesTypes():Promise<IConsumableTypes[]> {
        return this.get('api/consumable_type')
    }
    postVehicleDocs(formData: FormData, expiryDate?: string):Promise<string> {
        const url = expiryDate ? `api/upload_docs?expiration_date=${expiryDate}` : 'api/upload_docs';
        return this.post(url, formData);
    }
    postVehicle(form: IVehicleRequest): Promise<IVehicleRequest> {
        return this.post('api/vehicles', form)
    }
    putVehicle(form: IVehicleRequest, id: number): Promise<IVehicleRequest> {
        return this.put(`api/vehicles/${id}`, form)
    }
    getConsumablesById(vehicle_id:number):Promise<IPostConsumables[]> {
        return this.get(`api/vehicles/${vehicle_id}/vehicle_consumable`)
    }
    postConsumables(form: IPostConsumables[], vehicle_id: number) {
        return this.post(`api/vehicles/${vehicle_id}/vehicle_consumable`, form)
    }
    putConsumables(form: IPostConsumables[], vehicle_id: number) {
        return this.put(`api/vehicles/${vehicle_id}/vehicle_consumable`, form)
    }
    deleteVehicle(id: number) {
        return this.delete(`api/vehicles/${id}`)
    }
    getVehicleById(id: number):Promise<IVehicle> {
        return this.get(`api/vehicles/${id}`)
    }
    getVehicleMileageById(id: number):Promise<IMileageInfo[]> {
        return this.get(`api/vehicle/${id}/track`)
    }
    postVehicleMileageById(id: number, data: IPostMileage) {
        return this.post(`api/vehicle/${id}/track`, data)
    }
    deleteVehicleMileage(vehicleId: number, workTrackId: number){
        return this.delete(`api/vehicle/${vehicleId}/track/${workTrackId}`)
    }
    getFrontImage(id: number): Promise<AxiosResponse<Blob>> {
        return this.getBlob(`api/vehicles/${id}/image`, { responseType: 'blob' });
    }
    assignDriverToVehicle(vehicleId: number, driverId: number) {
        return this.post(`api/vehicles/${vehicleId}/drivers/${driverId}`)
    }
    deleteDriverFromVehicle(vehicleId: number, driverId: number) {
        return this.delete(`api/vehicles/${vehicleId}/drivers/${driverId}`)
    }
    getVehicleTypeById(vehicle_type_id: number):Promise<IVehicleType> {
        return this.get(`api/vehicle_types/${vehicle_type_id}`)
    }
    addVehicleType(data: Omit<IVehicleType, 'id'>):Promise<IVehicleType> {
        return this.post(`api/vehicle_types/`, data)
    }
    editVehicleTypeById(vehicle_type_id: number, data: Omit<IVehicleType, 'id'>):Promise<IVehicleType> {
        return this.put(`api/vehicle_types/${vehicle_type_id}`, data)
    }
    deleteVehicleTypeById(vehicle_type_id: number) {
        return this.delete(`api/vehicle_types/${vehicle_type_id}`)
    }
    downloadVehicleDoc(id: number, doc_type: string) {
        return this.getBlob(`api/vehicle/${id}/docs?doc_type=${doc_type}`)
    }
    getTrackingDocument(vehicle_id: number, start_period: string, end_period: string) {
        return this.getBlob(`api/vehicle/${vehicle_id}/tracking_document?start_period=${start_period}&end_period=${end_period}`)
    }
    changeVehicleStatus(vehicle_id: number, condition: string):Promise<{repair_card_id: string}> {
        return this.put(`api/vehicles/${vehicle_id}/repair`, {condition: condition})
    }
}
