// DriverForm.tsx
import React, { useState, useEffect } from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Box, FormHelperText } from '@mui/material';
import { IDriver } from '../../api/driversApi';

interface DriverFormProps {
    initialData?: IDriver;
    onSubmit: (data: Omit<IDriver, "id">) => void;
    onCancel: () => void;
}

const licenses = ["A", "B", "C", "D", "E"];

const DriverForm: React.FC<DriverFormProps> = ({ initialData, onSubmit, onCancel }) => {
    const [name, setName] = useState(initialData?.name || '');
    const [surname, setSurname] = useState(initialData?.surname || '');
    const [selectedLicenses, setSelectedLicenses] = useState<string[]>(initialData?.licenses || []);
    const [error, setError] = useState('');

    const handleSubmit = () => {
        if (selectedLicenses.length === 0) {
            setError('Должно быть выбрано хотя бы одно разрешение');
            return;
        }
        setError('');
        onSubmit({ name, surname, licenses: selectedLicenses });
    };

    return (
        <Box component="form" noValidate autoComplete="off">
            <TextField
                label="Имя"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Фамилия"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                fullWidth
                margin="normal"
            />
            <FormControl fullWidth margin="normal" variant="outlined" error={!!error}>
                <InputLabel>Лицензии</InputLabel>
                <Select
                    multiple
                    value={selectedLicenses}
                    onChange={(e) => setSelectedLicenses(e.target.value as string[])}
                    label="Лицензии"
                >
                    {licenses?.map((license) => (
                        <MenuItem key={license} value={license}>
                            {license}
                        </MenuItem>
                    ))}
                </Select>
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
            <Box display="flex" justifyContent="space-between" mt={2}>
                <Button onClick={onCancel}>Отмена</Button>
                <Button onClick={handleSubmit} variant="contained" color="primary">
                    Сохранить
                </Button>
            </Box>
        </Box>
    );
};

export default DriverForm;
