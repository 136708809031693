import React, {useEffect, useState} from 'react';
import {
    Box,
    Typography,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Link, Button,
} from '@mui/material';
import SectionDivider from "./SectionDivider";
import {IConsumableTypes, IPostConsumables, VehiclesApi} from "../../../../api/vehiclesApi";
import {IVehicle} from "../../../../api/authApi";
import {DriversApi} from "../../../../api/driversApi";
import {boolean} from "yup";
import {useAppContext} from "../../../store/AppContext";
import {ITemplate, TemplatesApi} from "../../../../api/templatesApi";
import dayjs from "dayjs";

interface RightSideInfoProps {
    consumables: IPostConsumables[];
    vehicle: IVehicle;
}

const RightSideInfo: React.FC<RightSideInfoProps> = ({ consumables,
                                                         vehicle,
                                                     }) => {
    const vehicleApi = new VehiclesApi();
    const driversApi = new DriversApi();
    const templatesApi = new TemplatesApi();
    const [consumableNames, setConsumableNames] = useState<IConsumableTypes[]>([]);
    const [templateNames, setTemplateNames] = useState<ITemplate[]>([]);
    const {driverId} = useAppContext();
    //для путевого листа
    const handleGetConsumableName = async () => {
        try {
            const [data, response] = await Promise.all([
                vehicleApi.getConsumablesTypes(),
                templatesApi.getTemplates(),
            ]);
            setConsumableNames(data);
            setTemplateNames(response);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const handleFindConsumableName = (id: number): string => {
        const consumable = consumableNames.find(item => item.id === id);
        return consumable ? consumable.name : 'Unknown';
    };
    const handleFindTemplateName = (id: number): string => {
        const template = templateNames.find(item => item.id === id);
        return template ? template.template_name : 'Unknown';
    }

    const handleDownloadDocument = async (id: number, docType: string, fileName: string) => {
        try {
            const response = await vehicleApi.downloadVehicleDoc(id, docType);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            let name: string;
            switch (docType) {
                case 'front_image':
                    name = 'Лицевая сторона ТС';
                    break;
                case 'registration':
                    name = 'Свидетельство о регистрации';
                    break;
                case 'tech_details':
                    name = 'Технический паспорт';
                    break;
                case 'insurance':
                    name = 'Страховой полис';
                    break;
                default:
                    name = 'Unknown Document';
            }
            const fileExtension = fileName.slice(fileName.lastIndexOf('.'));
            const finalFileName = `${name}${fileExtension}`;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', finalFileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (error) {
            console.error('Failed to download document:', error);
        }
    };

    const handleDownloadWayBill = async () => {
        try {
            if (driverId && vehicle.id) {
                const response = await driversApi.downloadWayBill(vehicle.id, +driverId);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'путевой_лист.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        void handleGetConsumableName();
    }, []);

    const checkDocumentDate = (docName: string | undefined) => {
        if (!docName) return null;

        // Extract the last 10 characters before ".pdf"
        const datePart = docName.slice(-14, -4);  // Extracts '24.09.2024'

        // Split the date into day, month, and year
        const [day, month, year] = datePart.split('.')?.map(Number);

        // Create a JavaScript Date object (month is 0-indexed)
        const documentDate = new Date(year, month - 1, day);

        // Get today's date
        const today = new Date();

        // Calculate the difference in time (in milliseconds)
        const diffInTime = documentDate.getTime() - today.getTime();

        // Convert the difference to days
        const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));

        if (diffInDays < 0) {
            return 'red'; // Date has passed
        } else if (diffInDays <= 30) {
            return 'orange'; // Date is within the next 30 days
        }

        return null; // Date is still valid beyond 30 days
    };

    const isValidDate = (dateString: string) => {
        console.log(dateString)
        const parsedDate = dayjs(dateString, 'DD.MM.YYYY', true); // true for strict parsing
        console.log(parsedDate)
        return parsedDate.isValid();
    };

    return (
        <Box>
            {
            consumables.length === 0 ?
                (
                <>
                    <SectionDivider text={'Норма'} />
                    <div>Нормы не найдены</div>
                </>
                ) : (
            consumables?.map((consumable, index) => (
                <React.Fragment key={index}>
                    <SectionDivider text={'Норма'} />
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Наименование нормы</TableCell>
                                    <TableCell>{handleFindConsumableName(Number(consumable.consumable_type_id))}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Норма потребления</TableCell>
                                    <TableCell>
                                        {consumable.consumption_rate} {consumable.consumption_unit} | {consumable.consumption_rate_calculation_interval} {consumable.replacement_interval_unit}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Объем</TableCell>
                                    <TableCell>{consumable.vehicle_consumable_volume} {consumable.consumption_unit}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Интервал замены</TableCell>
                                    <TableCell>{consumable.replacement_interval} {consumable.replacement_interval_unit}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Наименование расходника</TableCell>
                                    <TableCell>{handleFindTemplateName(Number(consumable.replacement_part_id))}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </React.Fragment>
            ))
                )}
            <Box>
                <SectionDivider text="Документы" />
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body1">
                                            Скачать: <Button onClick={()=> handleDownloadDocument(Number(vehicle?.id), 'front_image',vehicle?.doc_front_image )}>
                                            Лицевая сторона ТС
                                        </Button>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                        </TableBody>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1">
                                        Скачать: <Button
                                        sx={{
                                            color: checkDocumentDate(vehicle?.doc_registration) === 'red' ? 'red'
                                                : checkDocumentDate(vehicle?.doc_registration) === 'orange' ? 'orange'
                                                    : ''
                                        }}
                                        onClick={()=> handleDownloadDocument(Number(vehicle?.id), 'registration', vehicle?.doc_registration)}>
                                        Свидетельство о регистрации до {vehicle?.doc_registration?.match(/\d{2}\.\d{2}\.\d{4}/)}
                                    </Button>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1">
                                        Скачать: <Button
                                        sx={{
                                            color: checkDocumentDate(vehicle?.doc_tech_details) === 'red' ? 'red'
                                                : checkDocumentDate(vehicle?.doc_tech_details) === 'orange' ? 'orange'
                                                    : ''
                                        }}
                                        onClick={()=> handleDownloadDocument(Number(vehicle?.id), 'tech_details', vehicle?.doc_tech_details)}>
                                        Технический паспорт {vehicle?.doc_tech_details?.match(/\d{2}\.\d{2}\.\d{4}/)}
                                    </Button>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1">
                                        Скачать: <Button
                                        sx={{
                                            color: checkDocumentDate(vehicle?.doc_insurance) === 'red' ? 'red'
                                                : checkDocumentDate(vehicle?.doc_insurance) === 'orange' ? 'orange'
                                                    : ''
                                        }}
                                        onClick={()=> handleDownloadDocument(Number(vehicle?.id), 'insurance', vehicle?.doc_insurance)}>
                                        Страховой полис до {vehicle?.doc_insurance?.match(/\d{2}\.\d{2}\.\d{4}/)}
                                    </Button>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1">
                                        Скачать: <Button onClick={handleDownloadWayBill} disabled={!driverId}>Путевой лист</Button>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default RightSideInfo;
