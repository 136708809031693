// MultiStepForm.tsx
import React, {useEffect, useState} from 'react';
import { Stepper, Step, StepLabel, Button, Box, Typography } from '@mui/material';
import { useAppContext } from '../../store/AppContext';
import FormStep2 from './FormStep2/FormStep2';
import FormStep3 from './FormStep3/FormStep3';
import FormStep1 from './FormStep1/FormStep1';
import {useNavigate, useParams} from 'react-router-dom';
import FormStep4 from "./FormStep4/FormStep4";

const steps = ['Параметры', 'Нормативы', 'Документы', 'Готово'];

export interface IFormStep {
    handleNext: () => void;
    handleBack: () => void;
}

const MultiStepForm: React.FC = () => {
    const { id } = useParams<{ id?: string }>();
    const [activeStep, setActiveStep] = useState(0);
    const { formData, setFormData,
        setConsumableForm,
        setFiles,
        setFileNames ,
        handleGetVehicleById,
        setIsEditing
    } = useAppContext();
    const navigate = useNavigate();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setIsEditing(false);
        setFormData({});
        setConsumableForm([
            {
                consumable_type_id: null,
                consumption_rate: null,
                consumption_unit: '',
                consumption_rate_calculation_interval: null,
                vehicle_consumable_volume: null,
                replacement_interval: null,
                replacement_interval_unit: '',
                replacement_part_id: null,
            },
        ])
        setFiles({
            face: null,
            registration: null,
            technical: null,
            doc_insurance: null,
        });
        setFileNames({
            face: null,
            registration: null,
            technical: null,
            doc_insurance: null,
        })
        setActiveStep(0);
    };
    useEffect(() => {
        return () => {
            setFormData({});
            setConsumableForm([
                {
                    consumable_type_id: null,
                    consumption_rate: null,
                    consumption_unit: '',
                    consumption_rate_calculation_interval: null,
                    vehicle_consumable_volume: null,
                    replacement_interval: null,
                    replacement_interval_unit: '',
                    replacement_part_id: null,
                },
            ])
            setFiles({
                face: null,
                registration: null,
                technical: null,
                doc_insurance: null,
            });
            setFileNames({
                face: null,
                registration: null,
                technical: null,
                doc_insurance: null,
            })
            setIsEditing(false);
        };
    }, [setConsumableForm, setFileNames, setFiles, setFormData]);

    useEffect(() => {
        const fetchVehicleData = () => {
            if (id !== undefined) {
                void handleGetVehicleById(Number(id));
            }
        };
        fetchVehicleData();
    }, [id]);


    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps?.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box sx={{ mt: 2 }} display={'flex'} justifyContent={'center'}>
                {activeStep === steps.length ? (
                    <Box>
                        <Typography>Все шаги выполнены</Typography>
                        <Button onClick={handleReset}>Сброс</Button>
                    </Box>
                ) : (
                    <Box>
                        {activeStep === 0 && <FormStep1 handleNext={handleNext} />}
                        {activeStep === 1 && <FormStep2 handleNext={handleNext} handleBack={handleBack} />}
                        {activeStep === 2 && <FormStep3 handleNext={handleNext} handleBack={handleBack} />}
                        {activeStep === 3 && (
                            <FormStep4 handleNext={() => navigate('/vehicles')} handleBack={handleBack} />
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default MultiStepForm;
