import React, {createContext, useContext, useState, ReactNode, SetStateAction} from 'react';
import {IConsumableTypes, IPostConsumables, IVehicleType, VehiclesApi} from "../../api/vehiclesApi";
import {IVehicle} from "../../api/authApi";
import {log} from "node:util";
import {useNavigate} from "react-router-dom";

interface AppContextType {
    formData: any;
    setFormData: React.Dispatch<React.SetStateAction<any>>;
    handleGetVehicleTypes: () => unknown;
    vehicleTypes: IVehicleType[];
    setVehicleTypes: React.Dispatch<React.SetStateAction<IVehicleType[]>>;
    consumableTypes: IConsumableTypes[];
    setConsumableTypes: React.Dispatch<SetStateAction<IConsumableTypes[]>>;
    consumableForm: IPostConsumables[];
    setConsumableForm: React.Dispatch<React.SetStateAction<IPostConsumables[]>>;
    files: { [key: string]: File | null };
    setFiles: React.Dispatch<React.SetStateAction<{ [key: string]: File | null }>>;
    fileNames: { [key: string]: string | null };
    setFileNames: React.Dispatch<React.SetStateAction<{ [key: string]: string | null }>>;
    handleGatheredData: () => unknown;
    isAllUploadsSuccessful: boolean;
    setIsAllUploadsSuccessful: React.Dispatch<React.SetStateAction<boolean>>;
    activeVehicle: IVehicle | null;
    setActiveVehicle: React.Dispatch<SetStateAction<IVehicle | null>>;
    handleGetVehicleById: (id: number) => unknown;
    isEditing: boolean;
    setIsEditing: React.Dispatch<SetStateAction<boolean>>;
    driverId: number | string;
    setDriverId: React.Dispatch<SetStateAction<number | string>>;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const vehicleApi = new VehiclesApi();
    const navigate = useNavigate();
    const [formData, setFormData] = useState<any>({});
    const [activeVehicle, setActiveVehicle] = useState<IVehicle | null>(null);
    const [vehicleTypes, setVehicleTypes] = useState<IVehicleType[]>([])
    const [consumableTypes, setConsumableTypes] = useState<IConsumableTypes[]>([])
    const [isAllUploadsSuccessful, setIsAllUploadsSuccessful] = useState<boolean>(false)
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [driverId, setDriverId] = useState<number | string>('');
    const [files, setFiles] = useState<{ [key: string]: File | null }>({
        face: null,
        registration: null,
        technical: null,
    });
    const [fileNames, setFileNames] = useState<{ [key: string]: string | null }>({
        face: null,
        registration: null,
        technical: null,
    });
    const [consumableForm, setConsumableForm] = useState<IPostConsumables[]>([
        {
            consumable_type_id: null,
            consumption_rate: null,
            consumption_unit: '',
            consumption_rate_calculation_interval: null,
            vehicle_consumable_volume: null,
            replacement_interval: null,
            replacement_interval_unit: '',
            replacement_part_id: null,
        },
    ])
    const handleGetVehicleTypes = async () => {
        const response = await vehicleApi.getVehicleTypes();
        setVehicleTypes(response);
        const data = await vehicleApi.getConsumablesTypes();
        setConsumableTypes(data);
    }
    const handleGatheredData = async () => {
        let updatedFormData = { ...formData };
        if (fileNames.face) {
            updatedFormData.doc_front_image = fileNames.face;
        }
        if (fileNames.registration) {
            updatedFormData.doc_registration = fileNames.registration;
        }
        if (fileNames.technical) {
            updatedFormData.doc_tech_details = fileNames.technical;
        }
        if (fileNames.doc_insurance) {
            updatedFormData.doc_insurance = fileNames.doc_insurance;
        }
        try {
            if(isEditing){
                void vehicleApi.putVehicle(updatedFormData, Number(activeVehicle?.id));
                void vehicleApi.putConsumables(consumableForm, Number(activeVehicle?.id));
                // need to update endpoint to receive id in url and to accept array
            } else {
                const vehicle = await vehicleApi.postVehicle(updatedFormData);
                const vehicleId = vehicle.id;
                await vehicleApi.postConsumables(consumableForm, Number(vehicleId));
            }
        } catch (error) {
            console.error('Error posting data:', error);
        }
    };

    const handleGetVehicleById = async (id: number) => {
        try {
            const data = await vehicleApi.getVehicleById(id);
            setActiveVehicle(data);
            setFormData(data);
            setFileNames({face: data.doc_front_image, registration: data.doc_registration, technical: data.doc_tech_details, doc_insurance: data.doc_insurance })
            const response = await vehicleApi.getConsumablesById(id);
            setConsumableForm(response);
            if(data) {
                setIsEditing(true);
            }
        } catch (error) {
            console.error("Error fetching vehicle data:", error);
        }
    };

    return (
        <AppContext.Provider value={{
            activeVehicle,
            setActiveVehicle,
            formData,
            setFormData,
            handleGetVehicleTypes,
            setVehicleTypes,
            vehicleTypes,
            consumableTypes,
            setConsumableTypes,
            consumableForm,
            setConsumableForm,
            files,
            setFiles,
            fileNames,
            setFileNames,
            handleGatheredData,
            isAllUploadsSuccessful,
            setIsAllUploadsSuccessful,
            handleGetVehicleById,
            isEditing,
            setIsEditing,
            driverId,
            setDriverId
        }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};
