import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { TextField, Button, Box, Select, MenuItem, FormControl, IconButton, Typography, InputAdornment } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { useAppContext } from "../../../store/AppContext";
import { IPostConsumables } from "../../../../api/vehiclesApi";
import styles from './styles.module.scss';
import { ITemplate, TemplatesApi } from "../../../../api/templatesApi";
import {IFormStep} from "../MultiStepForm";


const FormStep2: React.FC<IFormStep> = ({ handleNext, handleBack }) => {
    const { consumableTypes, consumableForm, setConsumableForm } = useAppContext();
    const templateApi = new TemplatesApi();
    const [templates, setTemplates] = useState<{ [key: number]: ITemplate[] }>({});

    const addForm = () => {
        setConsumableForm([
            ...consumableForm,
            {
                consumable_type_id: null,
                consumption_rate: null,
                consumption_unit: '',
                consumption_rate_calculation_interval: null,
                vehicle_consumable_volume: null,
                replacement_interval: null,
                replacement_interval_unit: '',
                replacement_part_id: null,
            },
        ]);
    };

    const removeForm = (index: number) => {
        const newForms = consumableForm.filter((_, i) => i !== index);
        setConsumableForm(newForms);
    };

    const handleFormChange = (index: number, field: keyof IPostConsumables, value: any) => {
        const newForms = consumableForm.slice();
        newForms[index] = { ...newForms[index], [field]: value };
        if (field === 'consumable_type_id') {
            const selectedType = consumableTypes.find(type => type.id === value);
            if (selectedType) {
                newForms[index].consumption_unit = selectedType.unit;
            }
            handleGetTemplates(value, index);
        }
        setConsumableForm(newForms);
    };

    const handleGetTemplates = async (typeId: number, formIndex: number) => {
        try {
            const data = await templateApi.getTemplatesWithId(typeId);
            setTemplates(prevTemplates => ({
                ...prevTemplates,
                [formIndex]: data
            }));
        } catch (e) {
            console.log(e);
        }
    }

    const fetchTemplate = async (templateId: number, index: number) => {
        try {
            const template = await templateApi.getTemplateById(templateId);
            setConsumableForm((prevForms) => {
                const newForms = [...prevForms];
                newForms[index] = {
                    ...newForms[index],
                    ...template,
                    template_id: templateId,
                };
                return newForms;
            });
        } catch (e) {
            console.log(e);
        }
    };

    const getConsumableUnits = (typeId: number) => {
        const consumableType = consumableTypes.find(type => type.id === typeId);
        return consumableType ? consumableType.interval_units : [];
    };

    const convertToNumber = (value: any) => {
        const numberValue = Number(value);
        return isNaN(numberValue) ? value : numberValue;
    };

    const formik = useFormik<any>({
        initialValues: {
            consumable_type_id: null,
            consumption_rate: null,
            consumption_unit: '',
            consumption_rate_calculation_interval: null,
            replacement_interval: null,
            replacement_interval_unit: '',
            replacement_part_id: null,
            vehicle_consumable_volume: null,
            template_id: null
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const convertedValues = consumableForm.map((form) => ({
                ...form,
                consumption_rate: convertToNumber(form.consumption_rate),
                consumption_rate_calculation_interval: convertToNumber(form.consumption_rate_calculation_interval),
                vehicle_consumable_volume: convertToNumber(form.vehicle_consumable_volume),
                replacement_interval: convertToNumber(form.replacement_interval),
            }));
            setConsumableForm(convertedValues);
            void handleNext();
        },
    });

    useEffect(() => {
        const updateFormFromTemplate = async (templateId: number, index: number) => {
            try {
                const template = await templateApi.getTemplateById(templateId);
                setConsumableForm((prevForms) => {
                    return prevForms.map((form, i) => {
                        if (i === index) {
                            return {
                                ...form,
                                ...template,
                            };
                        }
                        return form;
                    });
                });
            } catch (e) {
                console.log(e);
            }
        };

        consumableForm.forEach((form, index) => {
            // if (form.replacement_part_id) {
            //     updateFormFromTemplate(form.replacement_part_id, index);
            // }
            if (form.consumable_type_id) {
                handleGetTemplates(form.consumable_type_id, index);
            }
        });
    }, []);

    return (
        <Box component="form" onSubmit={formik.handleSubmit} display="flex" flexDirection="column" alignItems="center" width="100%">
            {consumableForm.map((form, index) => (
                <Box key={index} className={styles.formRow}>
                    <Box className={styles.formColumn}>
                        <Typography className={styles.ellipsis}>Выберите норму</Typography>
                        <FormControl className={styles.formControlWide} margin="normal">
                            <Select
                                labelId={`consumable-label-${index}`}
                                name="consumable_type_id"
                                value={form.consumable_type_id}
                                onChange={(e) => handleFormChange(index, 'consumable_type_id', e.target.value)}
                            >
                                {consumableTypes.map((type) => (
                                    <MenuItem key={type.id} value={type.id}>
                                        {type.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className={styles.formColumn}>
                        <Typography className={styles.ellipsis}>Наименование расходника</Typography>
                        <FormControl className={styles.formControlWide} margin="normal">
                            <Select
                                labelId={`consumable-label-${index}`}
                                name="replacement_part_id"
                                value={form.template_id || ''}
                                onChange={(e) => { fetchTemplate(Number(e.target.value), index) }}
                            >
                                {(templates[index] || []).map((template) => (
                                    <MenuItem key={template.id} value={template.id}>
                                        {template.template_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className={styles.formColumn}>
                        <Typography className={styles.ellipsis}>Норма потребления</Typography>
                        <Box display="flex" gap={'4px'}>
                            <TextField
                                name="consumption_rate"
                                type="number"
                                value={form.consumption_rate}
                                onChange={(e) => handleFormChange(index, 'consumption_rate', e.target.value)}
                                className={styles.formControlNumber}
                                margin="normal"
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {form.consumption_unit}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                name="consumption_rate_calculation_interval"
                                type="number"
                                value={form.consumption_rate_calculation_interval}
                                onChange={(e) => handleFormChange(index, 'consumption_rate_calculation_interval', e.target.value)}
                                className={styles.formControlNumber}
                                margin="normal"
                                required
                            />
                            <FormControl className={styles.formControlUnit} margin="normal" style={{ marginLeft: 8 }}>
                                <Select
                                    name="replacement_interval_unit"
                                    value={form.replacement_interval_unit}
                                    onChange={(e) => handleFormChange(index, 'replacement_interval_unit', e.target.value)}
                                    required
                                >
                                    {getConsumableUnits(form.consumable_type_id as number).map((unit, i) => (
                                        <MenuItem key={i} value={unit}>
                                            {unit}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box className={styles.formColumn}>
                        <Typography>Объем</Typography>
                        <TextField
                            name="vehicle_consumable_volume"
                            type="number"
                            value={form.vehicle_consumable_volume}
                            onChange={(e) => handleFormChange(index, 'vehicle_consumable_volume', e.target.value)}
                            className={styles.formControlNumber}
                            margin="normal"
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {form.consumption_unit}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box className={styles.formColumn}>
                        <Typography>Интервал замены</Typography>
                        <Box display="flex">
                            <TextField
                                name="replacement_interval"
                                type="number"
                                value={form.replacement_interval}
                                onChange={(e) => handleFormChange(index, 'replacement_interval', e.target.value)}
                                className={styles.formControlNumber}
                                margin="normal"
                                required
                            />
                            <FormControl className={styles.formControlUnit} margin="normal" style={{ marginLeft: 8 }}>
                                <Select
                                    name="replacement_interval_unit"
                                    value={form.replacement_interval_unit}
                                    onChange={(e) => handleFormChange(index, 'replacement_interval_unit', e.target.value)}
                                    required
                                >
                                    {getConsumableUnits(form.consumable_type_id as number).map((unit, i) => (
                                        <MenuItem key={i} value={unit}>
                                            {unit}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <IconButton onClick={() => removeForm(index)} style={{ marginTop: '16px' }}>
                        <Remove />
                    </IconButton>
                </Box>
            ))}
            <Button onClick={addForm} startIcon={<Add />} variant="contained" color="primary">
                Добавить норматив
            </Button>
            <Box mt={2} display="flex" justifyContent="space-between" width="100%" padding={'0 16px 0 16px'}>
                <Button onClick={() => handleBack()} color="primary" variant="contained">
                    Назад
                </Button>
                <Button color="primary" variant="contained" type="submit">
                    Далее
                </Button>
            </Box>
        </Box>
    );
};

export default FormStep2;
