// AddVehicleMileageModal.tsx
import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, TextField, Typography, SxProps, Theme } from '@mui/material';

interface AddVehicleMileageModalProps {
    open: boolean;
    onClose: () => void;
    onAddMileage: (newMileage: number, trackingType: string) => void;
    trackingType: string;
    currentMileage: number;
    buttonStyle: SxProps<Theme>;
}

const AddMileageModal: React.FC<AddVehicleMileageModalProps> = ({ open, onClose, onAddMileage, currentMileage, buttonStyle, trackingType }) => {
    const [newMileage, setNewMileage] = useState(currentMileage);
    const [error, setError] = useState('');

    useEffect(() => {
        if (open) {
            setNewMileage(currentMileage);
            setError('');
        }
    }, [open, currentMileage]);

    const handleAddMileage = () => {
        if (newMileage < currentMileage) {
            setError('Новое значение пробега не может быть меньше текущего значения.');
        } else {
            onAddMileage(newMileage, trackingType);
            onClose();
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="add-mileage-modal-title"
            aria-describedby="add-mileage-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography id="add-mileage-modal-title" variant="h6" component="h2">
                    Добавить Пробег
                </Typography>
                <Typography id="add-mileage-modal-description" sx={{ mt: 2 }}>
                    Текущее значение пробега: {currentMileage}
                </Typography>
                <TextField
                    label="Новый Пробег"
                    type="number"
                    fullWidth
                    value={newMileage}
                    onChange={(e) => setNewMileage(Number(e.target.value))}
                    error={!!error}
                    helperText={error}
                    sx={{ mt: 2 }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button sx={buttonStyle} onClick={handleAddMileage}>Добавить</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default AddMileageModal;
