// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_formRow__M1zze {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.styles_formColumn__jUVyg {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 0 8px;
}

.styles_formControlWide__Ia6ax {
  width: 100%;
}

.styles_formControlNumber__\\+\\+Jd2 {
  width: 80px;
}

.styles_formControlUnit__KvSin {
  width: 80px;
}

.styles_ellipsis__pIxo1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/pages/InventoryPage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,cAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AACF","sourcesContent":[".formRow {\n  display: flex;\n  align-items: center;\n  margin-bottom: 8px;\n}\n\n.formColumn {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  padding: 0 8px;\n}\n\n.formControlWide {\n  width: 100%;\n}\n\n.formControlNumber {\n  width: 80px;\n}\n\n.formControlUnit {\n  width: 80px;\n}\n\n.ellipsis {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formRow": `styles_formRow__M1zze`,
	"formColumn": `styles_formColumn__jUVyg`,
	"formControlWide": `styles_formControlWide__Ia6ax`,
	"formControlNumber": `styles_formControlNumber__++Jd2`,
	"formControlUnit": `styles_formControlUnit__KvSin`,
	"ellipsis": `styles_ellipsis__pIxo1`
};
export default ___CSS_LOADER_EXPORT___;
