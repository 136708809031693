import React from 'react';
import { Box, Divider, Typography } from '@mui/material';

interface SectionDividerProps {
    text: string;
}

const SectionDivider: React.FC<SectionDividerProps> = ({ text }) => {
    return (
        <Box my={2}>
            <Divider>
                <Typography variant="subtitle1" textAlign="center">
                    {text}
                </Typography>
            </Divider>
        </Box>
    );
};

export default SectionDivider;
