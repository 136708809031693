import React from 'react';
import {Box, Button, Modal, Typography, SxProps, Theme, IconButton} from '@mui/material';
import {IMileageInfo, VehiclesApi} from '../../../api/vehiclesApi';
import DeleteIcon from '@mui/icons-material/Delete';
interface MileageInfoModalProps {
    open: boolean;
    onClose: () => void;
    mileageInfo: IMileageInfo[];
    vehicleId: number;
    buttonStyle: SxProps<Theme>;
    onMileageDeleted: () => void;
}

const MileageInfoModal: React.FC<MileageInfoModalProps> = ({ open, onClose, mileageInfo, buttonStyle, vehicleId,onMileageDeleted }) => {
    const vehiclesApi = new VehiclesApi();

    const handleDeleteMileage = async (mileageId: number) => {
        try {
            await vehiclesApi.deleteVehicleMileage(vehicleId, mileageId);
            onMileageDeleted();
        } catch (e) {
            console.error(e);
        }
    };
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="mileage-info-modal-title"
            aria-describedby="mileage-info-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography id="mileage-info-modal-title" variant="h6" component="h2">
                    Информация о пробеге
                </Typography>
                {mileageInfo.length === 0 ? (
                    <Typography id="mileage-info-modal-description" sx={{ mt: 2 }}>
                        Нет записей о пробеге.
                    </Typography>
                ) : (
                    mileageInfo.map((info) => (
                        <Box key={info.id} sx={{ mt: 2, borderBottom: '1px solid gray', pb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography>
                                    Тип: {info.track_type}
                                </Typography>
                                <Typography>
                                    Количество: {info.track_amount}
                                </Typography>
                                <Typography>
                                    Дата работы: {info.work_date}
                                </Typography>
                            </Box>
                            <IconButton
                                aria-label="delete"
                                color="secondary"
                                onClick={() => handleDeleteMileage(info.id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    ))
                )}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button sx={buttonStyle} onClick={onClose}>Закрыть</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default MileageInfoModal;