import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { IFormStep } from '../MultiStepForm';

const FormStep4: React.FC<IFormStep> = ({ handleNext }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            p={3}
        >
            <CheckCircleOutlineIcon color="primary" fontSize="large" />
            <Typography variant="h5" gutterBottom>
                Все готово!
            </Typography>
            <Typography variant="body1" paragraph>
                Вы успешно выполнили все шаги.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
            >
                Завершить
            </Button>
        </Box>
    );
};

export default FormStep4;
