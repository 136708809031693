import React, { useEffect, useState } from 'react';
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Button,
    SxProps,
    Theme, IconButton,
} from '@mui/material';
import { Add as AddIcon, Info as InfoIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import CommonContainer from '../components/CommonContainer/CommonContainer';
import {IMileageInfo, VehiclesApi} from "../../api/vehiclesApi";
import { useNavigate } from "react-router-dom";
import { IVehicle } from '../../api/authApi';
import DeleteConfirmationModal from './modals/DeleteConfirmationModal';
import AddMileageModal from './modals/AddMileageModal';
import styles from './styles.module.scss';
import useModal from "./modals/useModal";
import MileageInfoModal from "./modals/MlieageInfoModal";
import {toast} from "react-toastify";
import ConditionChangeModal from "./modals/ConditionChangeModal";

const buttonStyle: SxProps<Theme> = {
    backgroundColor: '#7e57c2',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#6a48b2',
    },
};
export const zebraStyle = (index: number): SxProps<Theme> => ({
    backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.04)' : 'white',
});

const conditionColor = (condition: string) => {
    switch (condition) {
        case 'В работе':
            return '#4CAF50';
        case 'На ремонте':
            return '#FFEB3B';
        case 'Требует ремонта':
            return '#FF9800';
        case 'В ожидании ремонта':
            return '#F44336';
        case 'На консервации':
            return '#9E9E9E';
        default:
            return '#BDBDBD';
    }
};
const smallIconButtonStyle: SxProps<Theme> = {
    minWidth: '24px',
    padding: '4px',
    margin: '0 4px',
    backgroundColor: 'transparent',
    color: '#7e57c2',
    '&:hover': {
        backgroundColor: 'transparent',
        color: '#6a48b2',
    },
};

const VehiclesMain: React.FC = () => {
    const [vehicles, setVehicles] = useState<IVehicle[]>([]);
    const [selectedVehicle, setSelectedVehicle] = useState<IVehicle | null>(null);
    const [mileageInfo, setMileageInfo] = useState<IMileageInfo[]>([]);
    const vehiclesApi = new VehiclesApi();
    const navigate = useNavigate();

    const { isOpen, openModal, closeModal } = useModal();

    const fetchVehicles = async () => {
        try {
            const response = await vehiclesApi.getVehicles();
            setVehicles(response);
        } catch (e) {
            toast.error('Ошибка при попытке загрузки подвижного состава')
        }
    };

    useEffect(() => {
        void fetchVehicles();
    }, []);

    const handleDeleteVehicle = async (id: number) => {
        await vehiclesApi.deleteVehicle(id);
        setVehicles(vehicles.filter(vehicle => vehicle.id !== id));
        closeModal('delete');
    };

    const handleOpenDeleteModal = (vehicle: IVehicle) => {
        setSelectedVehicle(vehicle);
        openModal('delete');
    };

    const handleOpenMileageModal = (vehicle: IVehicle) => {
        setSelectedVehicle(vehicle);
        openModal('mileage');
    };

    const handleOpenInfoModal = async (vehicle: IVehicle) => {
        setSelectedVehicle(vehicle);
        const response = await vehiclesApi.getVehicleMileageById(Number(vehicle.id));
        setMileageInfo(response);
        openModal('info');
    };

    const handleAddMileage = async (newMileage: number, trackingType: string) => {
        if (selectedVehicle) {
            try {
                const date = new Date();
                const formattedDate = date.toISOString().slice(0, 10);
                await vehiclesApi.postVehicleMileageById(Number(selectedVehicle.id), {track_type: trackingType, "updated_track_record": newMileage, "work_date": formattedDate });
                setVehicles(vehicles.map(v => {
                    if (v.id === selectedVehicle.id) {
                        if (trackingType === 'Пробег') {
                            return { ...v, mileage: newMileage };
                        } else {
                            return { ...v, moto_hours: newMileage };
                        }
                    }
                    return v;
                }));
                closeModal('mileage');
                toast.success('Пробег успешно обновлен');
            } catch (e: any) {
                toast.error(e.response.data.detail);
            }
        }
    };
    const handleMileageDeleted = async () => {
        await fetchVehicles();
        closeModal('info');
    };

    const handleOpenConditionChange = async (vehicle: IVehicle) => {
        setSelectedVehicle(vehicle);
        openModal('condition')
    }
    const handleChangeVehicleCondition = async (status: string) => {
        try {
            await vehiclesApi.changeVehicleCondition({condition: status}, selectedVehicle?.id as number)
        } catch (e) {
            console.log(e);
        }
        void fetchVehicles();
    }

    return (
        <CommonContainer>
            <Typography variant="h4" gutterBottom>
                Список подвижного состава
            </Typography>
            <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button sx={buttonStyle} onClick={() => navigate('/new-vehicle')}>
                    <AddIcon /> Добавить
                </Button>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Наименование ТС</TableCell>
                            <TableCell>Тип</TableCell>
                            <TableCell>Статус ТС</TableCell>
                            <TableCell>Пробег</TableCell>
                            <TableCell>Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {vehicles.map((vehicle, index) => (
                            <TableRow key={vehicle.id} onClick={() => navigate(`/vehicle-info/${vehicle.id}`)} sx={zebraStyle(index)}>
                                <TableCell>{vehicle.gos_number}</TableCell>
                                <TableCell>{vehicle.marka} {vehicle.model}</TableCell>
                                <TableCell>{vehicle.vehicle_type}</TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={(e) => e.stopPropagation()}>
                                        <Box
                                            sx={{
                                                width: 10,
                                                height: 10,
                                                borderRadius: '50%',
                                                backgroundColor: conditionColor(vehicle.condition),
                                                marginRight: 1,
                                            }}
                                        />
                                        {vehicle.condition}
                                        <IconButton onClick={(e) => handleOpenConditionChange(vehicle)}>
                                            <EditIcon/>
                                        </IconButton>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <div className={styles.mileage}>
                                        <div>
                                            {vehicle.default_tracking_type === 'Пробег' ? `${vehicle.mileage} км` : `${vehicle.moto_hours } м/ч`}
                                        </div>
                                        <div>
                                        <Button
                                            sx={smallIconButtonStyle}
                                            variant="text"
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleOpenInfoModal(vehicle);
                                            }}
                                        >
                                            <InfoIcon />
                                        </Button>
                                        <Button
                                            sx={smallIconButtonStyle}
                                            variant="text"
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleOpenMileageModal(vehicle);
                                            }}
                                            disabled={!(vehicle.condition === 'В работе' || vehicle.condition === 'Требует ремонта')}
                                        >
                                            <AddIcon />
                                        </Button>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className={styles.actions}>
                                    <Button sx={buttonStyle} variant="contained" size="small" style={{ marginRight: 8 }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(`/edit-vehicle/${vehicle.id}`)
                                            }}>
                                        <EditIcon /> Редактировать
                                    </Button>
                                    <Button
                                        sx={buttonStyle}
                                        variant="contained"
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleOpenDeleteModal(vehicle);
                                        }}
                                    >
                                        <DeleteIcon /> Удалить
                                    </Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedVehicle && (
                <DeleteConfirmationModal
                    open={isOpen['delete']}
                    vehicleMarka={selectedVehicle.marka}
                    onClose={() => closeModal('delete')}
                    onConfirm={() => handleDeleteVehicle(selectedVehicle.id as number)}
                    buttonStyle={buttonStyle}
                />
            )}
            {selectedVehicle && (
                <AddMileageModal
                    open={isOpen['mileage']}
                    onClose={() => closeModal('mileage')}
                    onAddMileage={handleAddMileage}
                    trackingType={selectedVehicle.default_tracking_type}
                    currentMileage={selectedVehicle?.default_tracking_type === 'Пробег' ? selectedVehicle?.mileage as number : selectedVehicle?.moto_hours as number}
                    buttonStyle={buttonStyle}
                />
            )}
            {mileageInfo && (
                <MileageInfoModal
                    open={isOpen['info']}
                    onClose={() => closeModal('info')}
                    mileageInfo={mileageInfo}
                    buttonStyle={buttonStyle}
                    vehicleId={selectedVehicle?.id as number}
                    onMileageDeleted={handleMileageDeleted}
                />
            )}
            {selectedVehicle && (
                <ConditionChangeModal
                    open={isOpen['condition']}
                    onClose={() => closeModal('condition')}
                    onSave={handleChangeVehicleCondition}
                />
            )}

        </CommonContainer>
    );
};

export default VehiclesMain;
