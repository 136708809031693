// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__xA9kk {
  padding: 16px;
  width: 100%;
}

.styles_title__8ZaNB {
  margin-bottom: 16px;
}

.styles_uploadSection__y2RtV {
  margin-bottom: 16px;
}

.styles_fileName__hb-oo {
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.styles_fileName__hb-oo span {
  margin-right: 8px;
}

.styles_buttons__PC-Yd {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 16px;
}

input[type=file] {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/VehiclesPage/MultiStepForm/FormStep3/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".container {\n  padding: 16px;\n  width: 100%;\n}\n\n.title {\n  margin-bottom: 16px;\n}\n\n.uploadSection {\n  margin-bottom: 16px;\n}\n\n.fileName {\n  margin-top: 8px;\n  margin-bottom: 8px;\n  display: flex;\n  align-items: center;\n}\n\n.fileName span {\n  margin-right: 8px;\n}\n\n.buttons {\n  display: flex;\n  justify-content: space-between;\n  gap: 16px;\n  margin-top: 16px;\n}\n\ninput[type=\"file\"] {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__xA9kk`,
	"title": `styles_title__8ZaNB`,
	"uploadSection": `styles_uploadSection__y2RtV`,
	"fileName": `styles_fileName__hb-oo`,
	"buttons": `styles_buttons__PC-Yd`
};
export default ___CSS_LOADER_EXPORT___;
