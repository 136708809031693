import { useState } from 'react';

const useModal = () => {
    const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({});

    const openModal = (modalName: string) => {
        setIsOpen((prev) => ({ ...prev, [modalName]: true }));
    };

    const closeModal = (modalName: string) => {
        setIsOpen((prev) => ({ ...prev, [modalName]: false }));
    };

    return {
        isOpen,
        openModal,
        closeModal,
    };
};

export default useModal;
